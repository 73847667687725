@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@500&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Instrument Sans", sans-serif;
}

*::selection {
  background-color: transparent;
}

:root {
  --def-color: #6759ff;
}

.support-modalbox-loader {
  position: absolute;
  height: 55vh;
  width: 50vw;
  background-color: #ffffff;
  z-index: 5;
  border-radius: 2rem;
  padding: 2rem 1.5rem;
  margin-top: -5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.myorder-con {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-top: 5%;
}
.subcon-myorders {
  height: auto;
  width: 25%;
  border-radius: 0.5rem;
  margin: 3rem;
  padding: 0.7rem;
  font-size: 0.7rem;
  position: relative;
  box-shadow: 2px 2px 8px #33333350;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.subcon-myorders:hover {
  transform: scale(1.1);
  transition: all ease-in-out 0.5s;
}

.subcon-myorders p {
  margin: 2% 0%;
}

.rating-order-1 {
  position: absolute;
  margin: 0;
  width: 120%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
}

.status {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  font-weight: bold;
}

.amount {
  position: absolute;
  bottom: 0.8rem;
  right: 0.6rem;
  font-size: 1rem;
}

.support-button {
  position: absolute;
  top: 2.4rem;
  left: 7rem;
  font-size: 0.8rem;
  color: #ffffff;
  background-color: #6759ff;
  border: 0;
  padding: 1% 2%;
  border-radius: 0.2rem;
  cursor: pointer;
}

.span-el {
  font-size: 0.78rem;
  margin-left: 0.25rem;
  font-weight: bold;
  color: #33333390;
}

.items-modalbox-con {
  position: absolute;
  height: 85%;
  width: 80%;
  background-color: #ffffff;
  z-index: 5;
  border-radius: 2rem;
  padding: 2rem 1.5rem;
  margin-top: 2%;
  overflow-y: scroll;
}

.support-modalbox-con {
  position: absolute;
  height: 55%;
  width: 50%;
  background-color: #ffffff;
  z-index: 5;
  border-radius: 2rem;
  padding: 2rem 1.5rem;
  margin-top: -5%;
  display: flex;
}

.support-modalbox-con > div > img {
  width: 35%;
  border-radius: 50%;
  align-self: center;
}

.support-modalbox-con > div {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.support-modalbox-con > div > h3 {
  align-self: center;
}

.support-modalbox-con > div p {
  margin: 3% 0%;
  font-size: 0.9rem;
}

.support-modalbox-con > div select {
  padding: 2% 0%;
}

.support-modalbox-con > div textarea {
  height: 50%;
  width: 80%;
}

.support-modalbox-con > div > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8%;
}

.support-modalbox-con > div > div > button {
  background-color: #6759ff;
  border: 0px;
  color: #ffffff;
  padding: 1% 2%;
  border-radius: 0.25rem;
  cursor: pointer;
}

.modal {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-image {
  position: absolute;
  height: 25%;
  width: 15%;
  right: 5%;
  top: 32.5%;
}

.myorder-body-header {
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #53545c;
}
.myorder-body-header1 {
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #53545c;
  margin-top: 1%;
  margin-bottom: 1%;
  border-width: 1px;
  border-style: solid;
  border-left: none;
  border-right: none;
  border-color: #53545c50;
  font-size: 1vw;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

.myorder-body-header2 {
  height: 10%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #53545c;
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 1vw;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

#orderimg {
  height: 130%;
  width: 20%;
}

.myorder-body-para {
  height: 4vh;
  width: 18%;
  margin: 0;
  border-style: solid;
  border-color: transparent;
  padding-left: 2%;
  cursor: pointer;
}

.myorder-body-para img {
  height: 100%;
  width: 17%;
}

.header-6 {
  width: 15%;
  margin-right: 2%;
}

.myorder-body-header2 p {
  overflow: hidden;
}

.myorder-body-header2 p :hover {
  overflow: scroll;
}

.pick-up-time {
  position: absolute;
  right: 20%;
  border: 0px;
  color: #ffffff;
  background-color: var(--def-color);
  padding: 0.5% 1%;
  border-radius: 0.25rem;
  cursor: pointer;
}

.date-time-pickup {
  position: absolute;
  right: 20%;
  width: 30%;
  display: flex;
  justify-content: space-around;
}
.date-time-pickup > button {
  border: 0px;
  color: #ffffff;
  background-color: var(--def-color);
  padding: 0.5% 2%;
  border-radius: 0.2rem;
  cursor: pointer;
}

.no-orders {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

.no-orders > img {
  height: 40%;
  border-radius: 50%;
}

.no-orders > h3 {
  font-size: 2rem;
  margin-top: 2.5%;
}

.no-orders > button {
  font-size: 0.8rem;
  margin-top: 2.5%;
  background-color: var(--def-color);
  border: 0;
  color: #fff;
  padding: 0.25% 0.8%;
  border-radius: 0.2rem;
  cursor: pointer;
}

#issues {
  height: 100% !important;
  display: grid !important;
  grid-template-columns: repeat(
    auto-fit,
    minmax(200px, 1fr)
  ); /* Adjust as needed */
  grid-gap: 10px; /* Adjust the gap between items */
  overflow: auto; /* Use auto for automatic scrollbars */
  margin-right: 5%;
}

.issue-box {
  width: 95% !important;
  padding: 2.5% 2.5%;
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #6759ff;
  border-radius: 0.25rem;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
}

.issue-box > svg {
  margin: 0 !important;
}

.issue-box > p {
  padding: 1% 0%;
  display: flex;
  font-family: "Instrument Sans", sans-serif;
  font-size: 0.5rem !important;
  margin: 0 !important;
  align-self: flex-start;
}

@media screen and (max-width: 600px) {
  .rating-order-1 {
    margin-bottom: -6%;
  }
  .myorder-con {
    margin-top: 15% !important;
    border: 0%;
  }
  .no-orders > img {
    height: 30%;
    border-radius: 50%;
  }

  .no-orders > h3 {
    font-size: 1.5rem;
    margin-top: 2.5%;
  }

  .no-orders > button {
    font-size: 0.8rem;
    margin-top: 5%;
    background-color: var(--def-color);
    border: 0;
    color: #fff;
    padding: 1% 2.5%;
    border-radius: 0.2rem;
    cursor: pointer;
  }

  .pick-up-time {
    position: relative;
    height: 3%;
    left: 50%;
    right: 20%;
    font-size: 0.7rem;
  }

  .date-time-pickup {
    position: absolute;
    right: 20%;
    left: 5%;
    width: 53%;
    display: flex;
    justify-content: space-between;
    top: 2.6%;
  }
  .date-time-pickup > button {
    right: -45%;
    border: 0px;
    color: #ffffff;
    background-color: var(--def-color);
    padding: 2% 5%;
    border-radius: 0.2rem;
    cursor: pointer;
    font-size: 0.75rem;
  }
  .support-modalbox-con {
    position: absolute;
    height: 85vh;
    width: 90vw;
    background-color: #ffffff;
    z-index: 5;
    border-radius: 2rem;
    padding: 2rem 1.5rem;
    margin-top: 0%;
    display: flex;
    flex-direction: column;
    z-index: 10;
    overflow: hidden;
  }

  .support-modalbox-con > div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2.5%;
  }

  .support-modalbox-con > div > h3 {
    align-self: center;
  }

  .support-modalbox-con > div p {
    margin: 3% 0%;
    font-size: 0.9rem;
  }

  .support-modalbox-con > div select {
    margin-top: 2.5%;
    padding: 2% 0%;
  }

  .support-modalbox-con > div textarea {
    margin-top: 2.5%;
    height: 33.5%;
    width: 80%;
  }

  .support-modalbox-con > div > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 8%;
  }

  .support-modalbox-con > div > div > button {
    background-color: #6759ff;
    border: 0px;
    color: #ffffff;
    padding: 1% 2%;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  #orderimg {
    height: 100%;
    width: 60%;
  }
  .status {
    position: absolute;
    top: 0.9rem;
    right: 0.7rem;
    font-weight: bold;
  }

  .myorder-con {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
    position: relative;
    z-index: 5;
    margin-top: -5%;
    align-content: flex-start;
  }
  .subcon-myorders {
    height: auto;
    width: 100%;
    border-radius: 0.5rem;
    margin: 1rem;
    font-size: 2.9vw;
    position: relative;
    box-shadow: 2px 2px 8px #33333350;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 8%;
    padding-bottom: 6%;
  }

  .subcon-myorders p {
    margin: 4% 0%;
  }

  .subcon-myorders span {
    font-size: 2.9vw;
  }

  .amount {
    font-size: 0.8rem;
    bottom: 1.25rem;
  }

  .support-button {
    position: relative;
    height: 3.5%;
    width: 40%;
    left: 5%;
    right: 60%;
    font-size: 0.7rem;
  }

  .amount span {
    font-size: 0.75rem;
  }

  .subcon-myorders p {
    line-height: 1.5%;
  }

  .subcon-myorders:hover {
    transform: scale(0.97);
    transition: all ease-in-out 0.5s;
  }

  .items-modalbox-con {
    position: absolute;
    height: 80vh;
    width: 88vw;
    background-color: #ffffff;
    z-index: 10;
    border-radius: 2rem;
    padding: 1.5rem;
    overflow-y: scroll;
    padding-left: 2%;
    padding-right: 0%;
    margin-top: 3%;
  }

  .items-modalbox-con button {
    position: absolute;
    top: 2%;
  }

  .header-6 {
    width: 30%;
    margin-right: 2%;
  }
  .myorder-body-header h6 {
    padding-left: 3%;
    padding-top: 10%;
  }

  .modal {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .myorder-body-header1 {
    height: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #53545c;
    border-width: 1px;
    border-style: solid;
    border-left: none;
    border-right: none;
    border-color: transparent;
    font-size: 1vw;
  }

  .myorder-body-header2 {
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #53545c;
    margin-top: 3%;
    margin-bottom: 1%;
    font-size: 1vw;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
  }

  .myorder-body-header2 p {
    overflow: hidden;
  }

  .myorder-body-header2 p :hover {
    overflow: scroll;
  }

  .myorder-body-para {
    height: 50%;
    width: 25%;
    margin: 0;
    border-style: solid;
    border-color: transparent;
    padding-left: 2%;
    cursor: pointer;
    font-size: 0.65rem;
  }

  .myorder-body-para img {
    height: 80%;
    width: 70%;
  }
}

.rating-box {
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 0.5rem;
  z-index: 20;
  padding: 2%;
  font-family: "Instrument Sans", sans-serif;
  color: #53545c;
}

.rating-box h3 {
  font-family: "Instrument Sans", sans-serif;
}

#review-button {
  position: relative;
  background-color: #6759ff;
  font-size: 0.8rem;
  color: #ffff;
  border: 0;
  padding: 2% 3%;
  border-radius: 0.25rem;
  cursor: pointer;
  bottom: 0;
  margin-top: 5%;
  margin-left: 39%;
  margin-right: 50%;
}

#cancle-button {
  position: absolute;
  top: 4%;
  right: 5%;
  font-size: 1.5rem;
  background-color: transparent;
  border: transparent;
  color: #6759ff;
  cursor: pointer;
}

.rating-main {
  position: relative;
  width: 69%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rating-main p {
  font-size: 0.7rem;
}

.review-service {
  border: 2px solid #53545c;
  padding: 2%;
  text-align: center;
  border-radius: 0.5rem;
  margin-top: 3.5%;
}

.review-service h3 {
  margin-bottom: 5%;
}

.review-service svg {
  margin: 2.5%;
  font-size: 2rem;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .rating-box {
    width: 85%;
  }
  #cancle-button {
    top: 1%;
  }
  .rating-box h3 {
    margin-top: 1%;
  }
  #review-button {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: landscape) {
  .rating-box {
    width: 35%;
  }
  #cancle-button {
    top: 2.5%;
  }
  .items-modalbox-con {
    margin-top: 0%;
  }
  .subcon-myorders {
    width: 38% !important;
  }
  .support-button {
    top: 5.5vh;
  }
  .myorder-body-para img {
    height: 100% !important;
  }
  .naving {
    padding-top: 2%;
  }
  .support-modalbox-con {
    width: 80%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1366px) and (orientation: landscape) {
  .rating-box {
    width: 35%;
  }
  #cancle-button {
    top: 2.5%;
  }
  .items-modalbox-con {
    margin-top: 0%;
  }
  .subcon-myorders {
    width: 25% !important;
  }
  .support-button {
    top: 5.5vh;
  }
  .myorder-body-para img {
    height: 100% !important;
  }
  .naving {
    padding-top: 2%;
  }
  .support-modalbox-con {
    width: 80%;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) and (orientation: portrait) {
  .subcon-myorders {
    width: 45% !important;
    margin-right: 1%;
    margin-left: 2%;
  }
  .items-modalbox-con {
    width: 90% !important;
    margin-top: 0%;
  }
  .support-button {
    top: 5.5vh;
  }
  .myorder-body-header1 {
    padding-top: 5%;
    height: 5% !important;
    font-size: 0.8rem;
  }
  .myorder-body-para {
    font-size: 0.8rem;
  }
  .myorder-body-para img {
    height: 100% !important;
    width: 30% !important;
    margin-top: -5%;
  }
  .support-modalbox-con {
    height: 35%;
    width: 80%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) and (orientation: portrait) {
  .subcon-myorders {
    width: 40% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .rating-box {
    width: 42.5%;
  }
  #cancle-button {
    top: 2.5%;
  }
}

@media scree and (min-width: 1025px) and (max-width: 1366px) and (orientation: portrait) {
  .myorder-con {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
    margin-top: 5%;
  }
  .subcon-myorders {
    height: auto;
    width: 25%;
    border-radius: 0.5rem;
    margin: 3rem;
    padding: 0.7rem;
    font-size: 0.7rem;
    position: relative;
    box-shadow: 2px 2px 8px #33333350;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  .subcon-myorders:hover {
    transform: scale(1.1);
    transition: all ease-in-out 0.5s;
  }

  .subcon-myorders p {
    margin: 1.5% 0%;
  }

  .rating-order-1 {
    position: absolute;
    margin: 0;
    width: 120%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
  }
}

/* @media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  .rating-box {
    width: 35%;
  }
  #cancle-button {
    top: 2.5%;
  }

  .items-modalbox-con {
    width: 90% !important;
    margin-top: 5%;
  }
  .support-button {
    top: 5.3vh;
  }
  .myorder-body-header1 {
    padding-top: 5%;
    height: 5% !important;
    font-size: 0.9rem;
  }
  .myorder-body-para {
    font-size: 0.9rem;
  }
  .myorder-body-para img {
    height: 100% !important;
    width: 30% !important;
    margin-top: -5%;
    border: 2px solid red !important;
  }

  .support-modalbox-con {
    height: 35%;
    width: 80%;
  }
} */
