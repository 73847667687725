* {
  -webkit-tap-highlight-color: transparent;
}

.washing-total-con-B {
  position: relative;
  width: 90vw;
  height: 84vh;
  margin-left: 5%;
  margin-top: 1%;
  margin-bottom: 5%;
  background: #ffffff;
  border-radius: 15px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.washing-anime-B {
  width: 30%;
  margin-top: 3%;
  margin-right: 9%;
  align-self: center;
}

.washing-para-B {
  font-family: "Courier New", Courier, monospace;
  font-size: 5vh;
  color: #2b8dfc;
  font-weight: bold;
  margin-top: 3%;
}

.washing-loader-B {
  position: absolute;
  height: 15%;
  top: 80%;
  left: 60%;
}

.washing-note-B {
  width: 60%;
  border-style: solid;
  border-color: #9359ff;
  border-width: 2px;
  color: #9359ff;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1vw;
  text-align: center;
  border-radius: 10px;
  align-self: center;
  font-size: 1rem;
  margin-top: 5%;
}

.go-back-button-B {
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #6759ff;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

@media screen and (max-width: 576px) {
  .washing-anime-B {
    width: 70%;
    margin-top: -10%;
    margin-right: 15%;
    align-self: center;
  }

  .washing-note-B {
    width: 90%;
    font-size: 0.8rem;
    border-radius: 0.4rem;
    margin-top: -5%;
  }

  .washing-total-con-B {
    position: relative;
    height: 50vh;
    margin-bottom: 5%;
  }

  .washing-total-con {
    position: relative;
    width: 90%;
    margin-top: 5%;
    margin-left: 5.5%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    display: flex;
    overflow: hidden;
    padding-top: 10%;
    padding-left: 5%;
    padding-right: 8%;
    padding-bottom: 20%;
    flex-wrap: wrap;
  }
  .washing-anime {
    width: 70%;
    height: 30%;
    margin-top: 15%;
    margin-left: -10%;
    margin-bottom: -5%;
  }

  .washing-para {
    font-family: "Courier New", Courier, monospace;
    font-size: 2.8vh;
    color: #2b8dfc;
    font-weight: bold;
    margin-top: 40%;
    margin-right: 20vw;
    width: 20%;
  }

  .washing-loader {
    position: absolute;
    height: 15%;
    top: 61%;
    left: 77%;
  }

  .washing-note {
    height: 20%;
    width: 100%;
    border-style: solid;
    border-color: #9359ff;
    border-width: 2px;
    color: #9359ff;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 6%;
    padding-bottom: 6%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.5vw;
    text-align: center;
    border-radius: 10px;
    margin-left: 5%;
  }

  .go-back-button-B {
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: #6759ff;
    margin: 0%;
    padding: 0;
  }
}

@media screen and (min-width: 577px) and (max-width: 1025px) and (orientation: landscape) {
  .washing-total-con-B {
    height: 40vh;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: portrait) {
  .washing-total-con-B {
    height: 40vh;
  }
  .washing-anime-B {
    width: 40%;
  }
}
