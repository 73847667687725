@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0px;
}

* {
  -webkit-tap-highlight-color: transparent;
}

@keyframes downAnimation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes upAnimation {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

/**New CSS **/

:root {
  --def-color: #6759ff;
}

.washitup-block1 {
  position: relative;
  height: auto;
  width: 100%;
  background-color: #b8dde3;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.main-page-content {
  height: auto;
  width: 40% !important;
  margin-top: 10%;
  margin-bottom: 18%;
  margin-left: 7.5%;
  position: relative;
  z-index: 1;
  font-family: "Poppins", sans-serif;
}

.main-page-content h4 {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  font-weight: 800;
}

.main-page-content span {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  font-weight: 800;
  margin-left: 2%;
  color: var(--def-color);
}

.main-page-content p {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
}

.cyan-image {
  position: absolute;
  left: 0%;
  top: 15%;
}

.pink-image {
  position: absolute;
  top: 15%;
  right: -8%;
  transform: rotate(15deg);
}
.bar-nav {
  height: 10%;
  width: 100%;
  border: 2px solid transparent;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 4%;
}

.nav-bar-contents {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.bar-nav > div img {
  height: 15%;
  width: 15%;
  object-fit: cover;
  margin-left: 5%;
  margin-right: 12.5%;
}

.bar-nav p {
  font-size: 0.8rem;
  cursor: pointer;
}

.bar-nav p:hover {
  color: var(--def-color);
}

.bar-nav button {
  background-color: var(--def-color);
  border: 0;
  padding: 1% 2%;
  color: #ffffff;
  border-radius: 0.3rem;
  cursor: pointer;
}

.bar-nav button:hover {
  background-color: var(--def-color);
  border: 0;
  padding: 1% 2%;
  color: #ffffff;
  border-radius: 0.3rem;
  filter: drop-shadow(1px 1px 8px var(--def-color));
}

.hamburger-icon {
  display: none;
}
.hamburger-icon svg {
  display: none;
}

.nav-bar-contents-mobile {
  display: none;
}

/**Mobile Portrait**/
@media screen and (max-width: 600px) and (orientation: portrait) {
  .cyan-image {
    top: 40%;
    left: -5%;
  }

  .pink-image {
    top: 30%;
    right: -40%;
  }
  .main-page-content {
    margin-left: 7%;
    width: 100% !important;
    margin-bottom: 0%;
  }

  .main-page-content h4 {
    font-size: 2rem !important;
  }
  .main-page-content span {
    font-size: 2rem !important;
  }

  .main-page-content p {
    font-size: 0.7rem !important;
  }

  .bar-nav {
    border: 2px solid transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0%;
    padding-right: 5%;
  }

  .nav-bar-contents p {
    display: none;
  }

  .nav-bar-contents {
    display: flex;
    justify-content: flex-start;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
  }

  .nav-bar-contents img {
    height: 100% !important;
    width: 40% !important;
  }

  .nav-bar-contents button {
    display: none;
  }

  .hamburger-icon {
    display: inline;
  }
  .hamburger-icon svg {
    display: inline;
  }

  .nav-bar-contents-mobile p {
    font-size: 1rem;
    padding: 0%;
    margin-left: 5%;
    width: 25%;
  }

  .nav-bar-contents-mobile p:active {
    color: #6759ff;
  }

  .nav-bar-contents-mobile {
    height: 65%;
    width: 100%;
    position: fixed !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 40%;
    background-color: #b8dde3;
    z-index: 5;
    padding-top: 18%;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    filter: drop-shadow(2px 2px 5px #000000);
    animation: downAnimation 0.5s ease-in-out;
  }

  .nav-bar-contents-mobile button {
    padding: 2% 5%;
    font-size: 1rem;
    margin-left: 5%;
  }

  .nav-bar-contents-mobile button:hover {
    padding: 2% 5%;
    font-size: 1rem;
    margin-left: 5%;
  }

  .nav-bar-contents-mobile1 p {
    font-size: 1rem;
    padding: 2%;
    margin-left: 5%;
    width: 100%;
  }

  .nav-bar-contents-mobile1 {
    height: 80%;
    width: 100%;
    position: fixed !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 40%;
    background-color: #b8dde3;
    z-index: 2;
    padding-top: 18%;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    filter: drop-shadow(2px 2px 5px #000000);
    animation: upAnimation 0.5s ease-in-out;
  }

  .nav-bar-contents-mobile1 button {
    padding: 2% 5%;
    font-size: 1rem;
    margin-left: 5%;
  }

  .nav-bar-contents-mobile1 button:hover {
    padding: 2% 5%;
    font-size: 1rem;
    margin-left: 5%;
  }

  .cross-mark-nav-bar {
    position: absolute;
    right: 5%;
    bottom: 7%;
    border: 0 !important;
    background-color: transparent !important;
    color: #6759ff !important;
  }
}

/**Mobile LandScape**/
@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: landscape) {
  .main-page-content h4 {
    font-size: 2rem;
  }
  .main-page-content span {
    font-size: 2rem;
  }
  .main-page-content p {
    font-size: 0.7rem;
  }
}

/**Tablet Portrait**/
@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: portrait) {
  .main-page-content {
    margin-left: 10%;
    width: 55%;
    margin-bottom: 25%;
  }

  .main-page-content h4 {
    font-size: 1.6rem;
  }
  .main-page-content span {
    font-size: 1.6rem !important;
  }

  .main-page-content p {
    font-size: 0.7rem;
  }
}

/**Tablet LandScape**/
@media screen and (min-width: 1024px) and (max-width: 1300px) and (orientation: landscape) {
  .main-page-content {
    margin-left: 10%;
  }

  .main-page-content h4 {
    font-size: 2.5rem;
  }

  .main-page-content span {
    font-size: 2.5rem;
  }

  .main-page-content p {
    font-size: 0.7rem;
  }
}

/**Tablet Portrait Ipad-pro**/
@media screen and (max-width: 1366px) and (orientation: portrait) {
  .main-page-content h4 span {
    font-size: 2.5rem;
  }
}

/**NewCss**/

/**Old Css**/
.error-con {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e8e6ff;
}

.error-con p {
  font-size: 1vw;
  font-family: Arial, Helvetica, sans-serif;
}

.image-error {
  height: 75%;
  width: 38%;
  filter: contrast(1);
  filter: brightness(1);
}

.error-button {
  font-size: 1.25vw;
  font-family: Arial, Helvetica, sans-serif;
  border-width: 0px;
  background-color: #6759ff;
  padding: 3px 10px 3px 10px;
  border-radius: 8px;
  color: #ffffff;
}

.connection-lost {
  height: 50%;
  width: 35%;
}

.error-con h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3.8vw;
  line-height: 3.5vw;
  margin-top: 1.5%;
  margin-bottom: 1.5%;
}

@media screen and (min-width: 992px) {
  .background22 {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 5vh;
    position: relative;
    overflow: hidden;
  }

  .offer-22 {
    height: 65%;
    width: 30%;
    border-radius: 10px;
    margin-top: 18vh;
  }

  .offer-22-head {
    position: absolute;
    top: 2.5vh;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 1000;
    color: #6759ff;
  }

  .last-section-icons {
    font-size: 2.5vw;
    margin-right: 2%;
    color: #dd246e;
    margin-top: 1%;
  }
  .last-section-icons1 {
    font-size: 2.2vw;
    margin-right: 2%;
    color: #dd246e;
    margin-top: 1%;
  }
  .women1 {
    height: 100%;
    margin-top: 8%;
    margin-left: 7%;
    margin-right: 5%;
    border-radius: 5%;
  }
  .women2 {
    height: 100%;
    margin-top: 12%;
    order: 2;
    border-radius: 5%;
  }
  .feature-icons {
    height: 35px;
    width: 35px;
    margin-right: 1%;
    margin-top: -1%;
  }
  .loc {
    height: 5vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1vw;
  }
  .bar {
    display: inline;
  }
  .hamburger {
    display: none;
  }
  .background1-con {
    position: relative;
    height: 120vh;
    width: 100vw;
    background-color: #b8dde3;
    background-size: cover;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 2px solid red;
  }
  .links {
    margin-left: 10vw;
  }

  .background-2 {
    height: auto;
    width: 100vw;
    background-color: #fff;
  }
  .back2-head {
    margin-top: 10vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 5vw;
    text-align: center;
    font-weight: bolder;
  }
  .back2-head2 {
    display: none;
  }
  .span2 {
    color: #6759ff;
  }
  .tagline {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.8vw;
    color: grey;
    text-align: center;
    margin-bottom: 5%;
  }

  .features {
    padding-left: 2vw;
    padding-right: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 45vh;
    width: 100%;
    cursor: pointer;
    position: relative;
  }

  .boxshow {
    padding-left: 2vw;
    padding-right: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 45vh;
    width: 100%;
  }

  .feature-name {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-top: 1.5vw;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-bottom: 0px;
    height: 80%;
    width: 20%;
    border-style: solid;
    border-radius: 7px;
    border-width: 0px;
    box-shadow: 2px 2px 2px 2px grey;
  }
  .f1 {
    background-color: #2d69f0;
    transition: all ease-in-out 0.5s;
  }
  .f1:hover {
    transform: scale(1.1);
  }
  .f2:hover {
    transform: scale(1.1);
  }
  .f3:hover {
    transform: scale(1.1);
  }
  .f4:hover {
    transform: scale(1.1);
  }
  .f2 {
    background-color: #dd246e;
    transition: all ease-in-out 0.5s;
  }

  .f3 {
    background-color: #6759ff;
    transition: all ease-in-out 0.5s;
  }

  .f4 {
    background-color: #0cae74;
    transition: all ease-in-out 0.5s;
  }

  .tagline2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
  }
  .tagline1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    text-align: start;
    padding-bottom: 3%;
  }

  .p1 {
    margin-top: 0.5vw;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3vw;
    font-weight: bolder;
  }
  .fa {
    font-size: 1vw;
  }
  .ic {
    height: 2.5w;
    width: 3vw;
    margin-bottom: 0%;
  }
  .dots {
    display: none;
  }

  .phone1 {
    display: flex;
    justify-content: space-around;
    height: 77vh;
    width: 100%;
    margin-bottom: 20vh;
    position: relative;
  }
  .phone2 {
    padding-left: 5vw;
    margin-top: 5vh;
    display: flex;
    justify-content: space-around;
    height: 77vh;
    width: 100%;
    margin-bottom: 35vh;
    padding-right: 5vw;
    position: relative;
  }
  .iphone {
    padding-top: 15vh;
    height: 90vh;
    width: 45%;
    margin-top: 5vh;
    margin-left: 5vw;
  }
  .iphone3 {
    padding-top: 15vh;
    height: 100vh;
    width: 50%;
    margin-top: 5vh;
    margin-left: 2vw;
  }
  .iphone2 {
    padding-top: 15vh;
    height: 85vh;
    width: 50%;
    margin-top: 10vh;
    margin-left: 5vw;
    order: 2;
  }
  .phone1-matter {
    display: flex;
    flex-direction: column;
    height: 77vh;
    width: 50%;
    text-align: start;
    margin-left: 5vw;
    justify-content: space-evenly;
  }
  .phh2 {
    padding-left: 10vw;
  }
  .fename {
    display: flex;
    margin-top: 5%;
    margin-bottom: 2.5%;
  }

  .fep1 {
    color: #6759ff;
    font-family: "Consolas";
    font-size: 1.3vw;
    margin-top: 25vh;
    margin-bottom: 2px;
    font-weight: bold;
  }
  .fep2 {
    color: #000000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3vw;
    font-weight: bold;
    margin-left: 0.5vw;
  }

  .fep3 {
    color: grey;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2vw;
    width: 90%;
  }
  .fep4 {
    padding-left: 0.5vw;
    padding-top: 0.8vw;
    color: grey;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1vw;
  }

  .fep5 {
    align-items: center;
    display: flex;
  }
  .lan {
    color: #000000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3vw;
    font-weight: bolder;
  }
  .visa {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: absolute;
    background-color: #6759ff;
    height: 25vh;
    border-radius: 20px;
    width: 20vw;
    margin-top: 35vh;
    margin-right: 37vw;
    z-index: 5;
  }
  .uifry {
    margin-top: 2vh;
    height: 3vh;
    width: 100%;
    transform: scale(1.2);
  }
  .visa-head {
    margin-left: 7vh;
    margin-top: 2vh;
    height: 3vh;
    width: 100%;
    transform: scale(1.2);
  }
  .number {
    margin-right: 8vw;
    margin-bottom: 5vh;
    font-size: 1vw;
    color: #ffffff;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    align-self: flex-end;
  }
  .number1 {
    margin-right: 8.5vw;
    margin-bottom: 0.5vh;
    font-size: 1vw;
    color: #ffffff;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    align-self: flex-end;
  }

  .valid {
    margin-right: 15vw;
    margin-bottom: 1.2vh;
    font-size: 0.5vw;
    color: #ffffff;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    align-self: flex-end;
  }
  .main-head {
    height: 45px;
    width: 180px;
    margin-left: 6vw;
  }
  .navbarcontainer {
    height: 10h;
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 2%;
  }

  .home {
    color: #000000;
  }
  .but {
    height: 7vh;
    width: 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6759ff;
    color: #fff;
    border-radius: 5px;
    border-width: 0px;
    position: relative;
    bottom: 20%;
  }
  .impink {
    position: absolute;
    align-self: flex-end;
    margin-top: 8%;
  }
  .content122 {
    height: auto;
    text-align: left;
    width: 50vw;
    margin-left: 9vw;
    margin-top: 13vh;
    display: flex;
    flex-direction: column;
    background-color: transparent;
  }
  .head {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3.2vw;
    color: #000000;
    font-weight: 900;
  }
  .sp {
    color: #6759ff;
  }
  .para1123 {
    font-family: Arial, Helvetica, sans-serif;
    color: #010101;
  }
  .butt2 {
    height: 8vh;
    width: 14vw;
    border-radius: 7px;
    border-width: 0px;
    color: #fff;
    background-color: #6759ff;
  }
  .cyancon {
    position: absolute;
    left: 0px;
    margin-top: 5vh;
    height: 70vh;
  }
  .but1 {
    margin-top: 0px;
    width: 20vw;
    height: 4vw;
    background-color: #6759ff;
  }

  .name3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    padding-left: 5%;
    width: 100%;
    border-style: solid;
    border-radius: 6px;
    border-width: 1px;
    border-color: grey;
    padding-top: 3%;
    padding-bottom: 3%;
    border-color: #6759ff;
    border-width: 1.5px;
    margin-top: 0%;
  }

  .geoLocator {
    color: #6759ff;
    position: absolute;
    font-size: 1.2vw;
    bottom: 17%;
    left: 2%;
  }

  .input4 {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 5%;
  }
  .co {
    position: absolute;
    left: 3.1vw;
    color: #6759ff;
    font-size: 1.5vw;
  }
  .add {
    height: 100%;
    position: absolute;
    left: 3.1vw;
  }
  .ad {
    color: #6759ff;
  }
  .address {
    font-family: sans-serif;
    border-radius: 6px;
    padding-left: 3%;
    font-size: 1vw;
    width: 100%;
    margin-bottom: 8%;
    border-color: #6759ff;
    border-width: 1.5px;
    padding-bottom: 20%;
  }
  .butt {
    margin-left: 12vw;
    margin-bottom: 3%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 5vw;
    padding-right: 5vw;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2vw;
  }
  .login {
    position: absolute;
    width: 43vw;
    height: 65vh;
    margin-left: 50%;
    margin-top: 13%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
    min-height: 65vh;
  }

  .alliphones {
    height: 120vh;
    width: 100vw;
    margin-top: 50vh;
    position: relative;
    overflow: hidden;
    background-color: #f0efff;
    margin-bottom: 25vh;
  }
  .iphoness {
    position: absolute;
    height: 130vh;
    width: 100%;
    left: 0px;
  }
  .back3-content {
    height: 30%;
    width: 45%;
    position: absolute;
    text-align: left;
    z-index: 1;
    margin-top: 12vh;
    margin-left: 10vw;
  }
  .b3para {
    margin-bottom: 0px;
    font-family: "Consolas";
    font-size: 1.2vw;
    color: #66666d;
  }

  .b3head {
    margin-top: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
    font-size: 2.5vw;
    margin-bottom: 2vh;
    text-align: left;
  }
  .b3butt {
    border-width: 0px;
    font-size: 1vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 6.5vh;
    width: 10vw;
    margin-right: 1.5vw;
    padding: 0;
  }
  .b33 {
    display: flex;
    margin-top: 2.5%;
  }

  .background4 {
    margin-top: 35vh;
    margin-bottom: 0px;
    height: 80vh;
    width: 100vw;
    background-color: #e1e1e1;
    position: relative;
  }
  .services-section {
    height: 30vh;
    width: 80vw;
    background-color: #1e3661;
    color: #fff;
    display: flex;
    justify-self: center;
    align-items: center;
    margin-left: 10%;
    position: absolute;
    border-radius: 20px;
    bottom: 80%;
    overflow: hidden;
  }
  .lastsection-para {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.5vw;
    text-align: left;
    padding-left: 8%;
    width: 60%;
    font-weight: 1000;
  }
  .buttons {
    width: 20%;
    display: flex;
    flex-direction: row;
    z-index: 1;
    position: absolute;
    margin-left: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
    border-color: transparent;
    border-radius: 10px;
    color: #6759ff;
    background-color: #fff;
  }
  .purple {
    height: 100%;
    transform: scale(1.3);
    position: absolute;
    margin-left: 75%;
    z-index: 0;
  }
  .butt5 {
    height: 7vh;
  }

  .contentb5 {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: flex-start;
    text-align: left;
    padding-top: 12%;
    padding-left: 10%;
    width: 100%;
  }
  .subscription {
    display: flex;
    flex-direction: column;
    width: 29%;
    margin-right: 5vw;
  }
  .subshead {
    font-family: Arial, Helvetica, sans-serif;
    width: 70%;
    font-weight: 1000;
  }
  .subspara {
    margin-top: 1vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    color: gray;
    margin-left: 4%;
    line-height: 1.5rem;
  }
  .subsnews {
    margin-top: 2vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    margin-bottom: 2vh;
    color: #000;
  }
  .subsinput {
    height: 8vh;
    width: 25vw;
    border-width: 0px;
    padding-left: 1vw;
    font-size: 1vw;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
    margin-bottom: 10%;
  }

  .button-container-5 {
    height: 5vh;
    width: 5vw;
    position: relative;
    margin-bottom: 7%;
  }

  .b5butt {
    position: absolute;
    background-color: #6759ff;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-color: #6759ff;
    padding-left: 1vw;
    padding-right: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    border-radius: 5px;
    top: 1.5vh;
    left: 15.5vw;
  }
  .fb {
    margin-top: 3vh;
    margin-bottom: 0px;
  }
  .sar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 70%;
  }
  .services {
    height: 25vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    color: gray;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
  .b5head {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3vw;
    font-weight: bolder;
    color: #000;
  }
  .last-3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1vw;
    color: #000;
    text-align: center;
    background-color: #e1e1e1;
    margin-top: 2.5%;
  }

  .navbar-nav {
    height: 8vh;
    margin-left: 20%;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: 0;
  }

  .donotshow {
    height: 8vh;
    margin-left: 20%;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .connection-lost {
    height: 25%;
    width: 75%;
  }

  .error-con h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8vw;
    line-height: 8vw;
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .error-con p {
    font-size: 3vw;
    font-family: Arial, Helvetica, sans-serif;
    width: 80%;
    text-align: center;
  }

  .error-button {
    font-size: 3.5vw;
    font-family: Arial, Helvetica, sans-serif;
    border-width: 0px;
    background-color: #6759ff;
    padding: 5px 15px 5px 15px;
    border-radius: 8px;
    color: #ffffff;
  }

  .image-error {
    height: 35%;
    width: 70%;
    filter: contrast(1);
    filter: brightness(1);
  }

  .background22 {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 3vh;
    position: relative;
    overflow: hidden !important;
  }

  .offer-22 {
    height: 20vh;
    width: 80%;
    border-radius: 10px;
    margin-top: 20%;
  }

  .offer-22-head {
    position: absolute;
    top: 2.5vh;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 1000;
    color: #6759ff;
  }
  .last-section-icons {
    font-size: 7vw;
    margin-right: 3%;
    color: #dd246e;
    margin-top: 5%;
  }
  .last-section-icons1 {
    font-size: 6.3vw;
    margin-right: 3%;
    color: #dd246e;
    margin-top: 5%;
  }
  .women1 {
    position: absolute;
    height: 48%;
    width: 85%;
    margin-top: 15.5vh;
    border-radius: 5%;
  }
  .women2 {
    position: absolute;
    height: 48%;
    width: 85%;
    margin-top: 15vh;
    border-radius: 5%;
  }
  .feature-icons {
    height: 30px;
    width: 30px;
    margin-right: 3%;
  }
  .bar {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
  }
  .hamburger {
    height: 2%;
    width: 2vw;
    margin-right: 10vw;
    margin-top: 2vh;
  }
  .background1-con {
    position: relative;
    height: auto;
    width: 100vw;
    background-color: #b8dde3;
    background-size: cover;
    overflow: hidden !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 5%;
    padding-right: 0px;
  }

  .navbarcontainer {
    display: inline;
    flex-direction: column;
    height: auto;
    width: 85%;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 2vh;
  }

  .links {
    margin-left: 10vw;
  }

  .background-2 {
    height: auto;
    width: 100vw;
    background-color: #fff;
    overflow: hidden !important;
    padding-bottom: 25%;
  }

  .back2-head {
    display: none;
  }

  .back2-head2 {
    margin-top: 10vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 8vw;
    text-align: center;
    font-weight: 1000;
    margin-bottom: 8vh;
  }
  .span2 {
    color: #6759ff;
    margin-left: 5%;
  }
  .tagline {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3.5vw;
    color: grey;
    text-align: center;
  }

  .features {
    padding-left: 2vw;
    padding-right: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding-bottom: 15%;
    margin-top: 5%;
    position: relative;
  }

  .feature-name {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    height: 85%;
    width: 85%;
    border-style: solid;
    border-radius: 7px;
    border-width: 0px;
    box-shadow: 2px 2px 2px 2px grey;
  }
  .f1 {
    display: inline;
    background-color: #2d69f0;
    transition: all ease-in-out 0.5s;
  }

  .f2 {
    display: inline;
    background-color: #dd246e;
    transition: all ease-in-out 0.5s;
  }

  .f3 {
    display: inline;
    background-color: #6759ff;
    transition: all ease-in-out 0.5s;
  }

  .f4 {
    display: inline;
    background-color: #0cae74;
    transition: all ease-in-out 0.5s;
  }

  .tagline2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 5vw;
    text-align: start;
    margin-top: 3vh;
    margin-left: 5vw;
  }
  .tagline1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 4.5vw;
    text-align: start;
    margin-top: 15vh;
    margin-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 8%;
  }

  .p1 {
    margin-top: 10vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 5.5vw;
    font-weight: bolder;
    position: absolute;
    margin-left: 5vw;
  }
  .fa {
    font-size: 1vw;
  }
  .ic {
    height: 5vh;
    width: 12vw;
    align-self: flex-start;
    margin-top: 3vh;
    position: absolute;
    left: 13vw;
  }
  .dots {
    display: inline;
    margin-left: 15%;
    width: 70%;
    display: flex;
    justify-content: space-around;
    padding: 2%;
  }
  .d1 {
    border-width: 0px;
    background: grey;
    border-radius: 10px;
    padding: 3%;
  }

  .iphone {
    position: absolute;
    padding-top: 15vh;
    height: 60%;
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    padding-bottom: 8%;
  }
  .iphone3 {
    position: absolute;
    padding-top: 15vh;
    height: 60%;
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    padding-bottom: 8%;
  }
  .iphone2 {
    position: absolute;
    padding-top: 15vh;
    height: 60%;
    width: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    padding-bottom: 8%;
  }

  .phh2 {
    padding-left: 10vw;
  }

  .visa {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: absolute;
    background-color: #6759ff;
    height: 25%;
    border-radius: 20px;
    width: 20vw;
    margin-top: 35vh;
    margin-right: 37vw;
    z-index: 5;
  }
  .uifry {
    margin-top: 2vh;
    height: 3%;
    width: 100%;
    transform: scale(1.2);
  }
  .visa-head {
    margin-left: 7vh;
    margin-top: 2vh;
    height: 3%;
    width: 100%;
    transform: scale(1.2);
  }
  .number {
    margin-right: 8vw;
    margin-bottom: 5vh;
    font-size: 1vw;
    color: #ffffff;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    align-self: flex-end;
  }
  .number1 {
    margin-right: 8.5vw;
    margin-bottom: 0.5vh;
    font-size: 1vw;
    color: #ffffff;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    align-self: flex-end;
  }

  .valid {
    margin-right: 15vw;
    margin-bottom: 1.2vh;
    font-size: 0.5vw;
    color: #ffffff;
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    align-self: flex-end;
  }
  .main-head {
    height: 32.5px;
    width: 130px;
    margin-left: 6vw;
    margin-top: -1%;
  }

  .navbar-nav {
    margin-left: 9.5%;
    margin-top: 5%;
    height: 33vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0px;
    background-color: transparent !important;
    border: 0;
    outline: none !important;
  }

  .navbar-nav:focus {
    outline: none, im !important;
    background-color: transparent !important;
  }

  .navbar-nav:focus-visible {
    outline: none;
    background-color: transparent;
  }

  .home {
    color: #000000;
  }
  .but {
    height: 100%;
    display: flex;
    width: 25vw;
    justify-content: center;
    align-items: center;
    background-color: #6759ff;
    color: #fff;
    border-radius: 2px;
    border-width: 0px;
    position: relative;
    bottom: 20%;
    border-radius: 5px;
    padding-top: 5%;
    padding-bottom: 8%;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 5%;
  }
  .impink {
    position: absolute;
    align-self: flex-end;
    margin-top: 10%;
    left: 90%;
    z-index: 0;
    margin-right: 0px;
  }
  .content112 {
    height: auto;
    text-align: left;
    width: 80vw;
    margin-left: 8%;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
  }
  .head {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 7vw;
    color: #000000;
    font-weight: 900;
    margin-top: 10%;
    margin-left: 8%;
  }
  .sp {
    color: #6759ff;
  }
  .para-con1123 {
    z-index: 2;
  }
  .para1123 {
    font-family: Arial, Helvetica, sans-serif;
    color: #010101;
    font-size: 3.5vw;
    margin-left: 8%;
  }
  .butt2 {
    width: 38vw;
    border-radius: 7px;
    border-width: 0px;
    color: #fff;
    background-color: #6759ff;
    margin-left: 8%;
    padding: 3%;
  }
  .cyancon {
    position: absolute;
    left: -15px;
    margin-top: 30vh;
    height: 70vh;
  }
  .but1 {
    margin-top: 0px;
    width: 60vw;
    padding: 3%;
    background-color: #6759ff;
  }

  #phone {
    margin-right: 5%;
  }

  .otp-button {
    margin-right: 4%;
  }

  .name3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3vw;
    padding-top: 4%;
    padding-bottom: 4%;
    width: 90%;
    border-style: solid;
    border-radius: 6px;
    border-width: 1px;
    border-color: grey;
    margin-left: 6%;
  }

  .geoLocator {
    position: absolute;
    color: #6759ff;
    font-size: 4vw;
    left: 8%;
    top: 58%;
  }

  .input4 {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 5%;
  }
  .co {
    position: absolute;
    left: 6vw;
    color: #6759ff;
    font-size: 5.5vw;
    margin-top: 2vh;
  }
  .add {
    height: 100%;
    position: absolute;
    left: 6vw;
    margin-top: 1vh;
  }
  .ad {
    color: #6759ff;
  }
  .address {
    border-radius: 6px;
    margin-left: 2.3vw;
    padding-left: 3.5%;
    padding-bottom: 20%;
    width: 89%;
    font-size: 3vw;
    padding-top: 1vh;
  }
  .butt {
    margin-left: 12vw;
    margin-bottom: 3%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 5vw;
    padding-right: 5vw;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 4vw;
  }
  .login {
    position: relative;
    width: 85%;
    margin-top: 5%;
    margin-left: 8%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    display: flex;
    overflow: hidden;
    padding-top: 4%;
    padding-bottom: 2%;
  }

  .blog {
    margin-bottom: 2vh;
  }

  .alliphones {
    width: 100vw;
    margin-top: 20vh;
    position: relative;
    overflow: hidden;
    background-color: #f0efff;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .iphoness {
    position: relative;
    height: 50%;
    width: 120%;
    left: 15%;
    margin-top: 45%;
    transform: scale(1.3);
    align-self: flex-end;
  }
  .back3-content {
    height: 30%;
    width: 80%;
    position: absolute;
    text-align: left;
    z-index: 1;
    margin-top: 8vh;
    margin-left: 10vw;
  }
  .b3para {
    margin-top: -10%;
    margin-bottom: -3%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3.5vw;
    color: #66666d;
  }

  .b3head {
    margin-top: 2vh;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
    font-size: 7vw;
    margin-bottom: -3%;
    text-align: left;
  }
  .b3des {
    margin-top: 2vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3.5vw;
    margin-bottom: 0px;
    text-align: left;
  }
  .b3butt {
    border-width: 0px;
    font-size: 2.7vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 25vw;
    margin-right: 1.5vw;
    font-weight: bold;
  }
  .b33 {
    display: inline-flex;
    margin-top: 5%;
    margin-left: -2%;
  }

  .background4 {
    margin-top: 35vh;
    width: 100vw;
    background-color: #e1e1e1;
    position: relative;
  }
  .services-section {
    width: 85vw;
    background-color: #1e3661;
    color: #fff;
    display: flex;
    justify-self: center;
    align-items: center;
    margin-left: 8%;
    position: absolute;
    border-radius: 20px;
    bottom: 80%;
    overflow: hidden;
  }
  .lastsection-para {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.5vw;
    text-align: left;
    padding-left: 8%;
    width: 100%;
  }
  .buttons {
    width: 10%;
    display: flex;
    flex-direction: row;
    z-index: 1;
    position: absolute;
    margin-left: 65%;
  }
  .purple {
    height: 100%;
    transform: scale(1.3);
    position: absolute;
    margin-left: 76%;
    z-index: 0;
  }

  .contentb5 {
    display: flex;
    flex-direction: row;
    justify-self: flex-start;
    align-items: flex-start;
    text-align: left;
    padding-top: 12%;
    padding-left: 10%;
    width: 100%;
  }
  .subscription {
    display: flex;
    flex-direction: column;
    width: 29%;
    margin-right: 5vw;
  }
  .subshead {
    width: 50%;
  }
  .subspara {
    margin-top: 1vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2vw;
    color: gray;
    margin-left: 3%;
  }
  .subsnews {
    margin-top: 1vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.4vw;
    color: #000;
  }

  .b5butt {
    position: absolute;
    margin-top: 16.3%;
    margin-left: 16.1%;
    background-color: #6759ff;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 8%;
    width: 8.5%;
  }
  .fb {
    margin-top: 3vh;
  }
  .sar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 70%;
  }
  .services {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1vw;
    color: gray;
  }
  .b5head {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3vw;
    font-weight: bolder;
    color: #000;
  }
  .last-3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
    color: #000;
    position: absolute;
    top: 167%;
    left: 30%;
  }

  .phone1 {
    padding: 5vw;
    display: flex;
    justify-content: space-around;
    height: 128vh;
    width: 100%;
    margin-top: 7vh;
    overflow: hidden;
    position: relative;
  }
  .phone2 {
    display: flex;
    justify-content: space-around;
    height: 128vh;
    width: 100%;
    padding: 5vw;
    margin-top: 7vh;
    overflow: hidden;
    margin-bottom: 7vh;
    position: relative;
  }
  .phone1-matter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    text-align: left;
  }
  .fep1 {
    color: #6759ff;
    font-family: "Consolas";
    font-size: 4vw;
    margin-bottom: 2px;
    font-weight: bold;
  }
  .lan {
    color: #000000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 6vw;
    font-weight: bolder;
  }

  .fep2 {
    color: #000000;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 5vw;
    font-weight: bold;
    margin-left: 0.5vw;
  }

  .fep3 {
    color: grey;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3.5vw;
  }

  .fename {
    display: flex;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .fep4 {
    margin-left: 2vw;
    color: grey;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 4vw;
  }

  .fep5 {
    font-size: 3vw;
    align-items: center;
    display: flex;
  }
  .visa {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: absolute;
    background-color: #6759ff;
    height: 12vh;
    border-radius: 10px;
    width: 42vw;
    margin-top: 26vh;
    margin-left: 75vw;
    z-index: 5;
  }
  .uifry {
    margin-top: 0.5vh;
    height: 3vh;
    width: 100%;
    transform: scale(0.9);
  }
  .visa-head {
    margin-left: 7vh;
    margin-top: 0.5vh;
    height: 3vh;
    width: 100%;
    transform: scale(0.6);
  }
  .number {
    top: 7vh;
    width: 2vw;
    margin-right: 36vw;
    font-size: 4vw;
    color: #ffffff;
    position: absolute;
    font-family: "Roboto";
    align-self: flex-end;
  }
  .number1 {
    margin-right: 2vw;
    margin-bottom: 0.5vh;
    font-size: 4vw;
    color: #ffffff;
    position: absolute;
    font-family: "Roboto";
    align-self: flex-end;
  }
  .valid {
    margin-right: 18vw;
    margin-bottom: 1.2vh;
    font-size: 0.5vw;
    color: #ffffff;
    position: absolute;
    font-family: "Roboto";
    align-self: flex-end;
  }

  .services-section {
    height: 20vh;
    z-index: 2;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .lastsection-para {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 5vw;
    width: 100%;
    margin-bottom: 10vh;
    margin-left: 0px;
  }
  .buttons {
    width: 35%;
    left: -46vw;
    bottom: 2.5vh;
    font-size: 4vw;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 8px;
    border-color: transparent;
    color: #6759ff;
  }

  .butt5 {
    width: 25vw;
    height: 6vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3vw;
    margin-right: 5vw;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
  }
  .purple {
    z-index: -1;
    margin-bottom: 5vh;
    transform: rotate(-15deg);
  }

  .card55 {
    position: relative;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-top: 10vh;
    height: 70vh;
    width: 100%;
    background: #e1e1e1;
    z-index: 1;
  }
  .background5 {
    height: 100%;
    width: 100%;
  }
  .contentb5 {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .subscription {
    margin-top: 15vw;
    width: 90%;
  }
  .subshead {
    font-weight: 1000;
    font-size: 6vw;
    color: black;
    margin-bottom: 0px;
  }

  .subspara {
    margin-top: 1vh;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .subsnews {
    font-family: Arial, Helvetica, sans-serif;
    color: #000000;
    margin-top: 5vh;
    margin-bottom: 1vh;
    font-size: 4vw;
  }
  .subsinput {
    border-radius: 5px;
    font-size: 3.5vw;
    padding-left: 2%;
    padding-top: 5%;
    padding-bottom: 5%;
    width: 95%;
    border-width: 0px;
    margin-bottom: 10%;
  }

  .sar {
    position: absolute;
    margin-top: 50vh;
    left: 0px;
    background-color: #e1e1e1;
    flex-wrap: wrap;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .services {
    color: grey;
    font-family: Arial, Helvetica, sans-serif;
    height: 33vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 3vw;
    margin-left: 10%;
  }

  .services p {
    margin-top: 10%;
    margin-bottom: 5%;
  }
  .b5head {
    font-size: 5vw;
    color: #000000;
  }
  .last {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3vw;
    margin-left: 6%;
    margin-top: 65vh;
  }

  .button-container-5 {
    position: relative;
    width: 100%;
  }
  .b5butt {
    border-width: 0px;
    position: absolute;
    border-radius: 5px;
    background-color: #6759ff;
    color: #ffffff;
    font-size: 4vw;
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 1vw;
    top: -55%;
    left: 45%;
  }
  .donotshow {
    display: none;
  }

  .boxshow {
    display: none;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .navbarcontainer {
    display: none;
  }
  .hamburger {
    display: none;
  }
}

/**Old Css**/
