@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;900&display=swap");

/**NewCss**/

* {
  -webkit-tap-highlight-color: transparent;
}

.success-total-con-A {
  position: absolute;
  background-color: #ffffff;
  height: 60%;
  right: 6%;
  top: 27.5%;
  padding: 1%;
  width: 45%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.success-anime-A {
  width: 40%;
}

.success-para-A {
  font-family: "Courier New", Courier, monospace;
  font-size: 3rem;
  color: #2b8dfc;
  font-weight: bold;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .success-total-con-A {
    height: 45vh;
    width: 90%;
    position: relative;
    margin-top: 10%;
    margin-left: 11.5% !important;
    margin-bottom: 10%;
  }

  .success-anime-A {
    width: 50%;
  }
  .success-para-A {
    font-size: 3.5rem;
  }
}

/**NewCss**/

@media screen and (min-width: 992px) {
  .success-total-con {
    position: absolute;
    width: 43vw;
    height: 65vh;
    margin-left: 50%;
    margin-top: 13%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    overflow: hidden;
    padding-left: 3%;
    padding-top: 5%;
    padding-bottom: 3%;
    padding-right: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .success-anime {
    width: 40%;
  }

  .success-para {
    font-family: "Courier New", Courier, monospace;
    font-size: 5vh;
    color: #2b8dfc;
    font-weight: bold;
    margin-top: 3%;
  }
}

@media screen and (max-width: 576px) {
  .success-total-con {
    position: relative;
    width: 85%;
    margin-top: 5%;
    margin-left: 8%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
    padding-top: 20%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 20%;
    flex-wrap: wrap;
  }
  .success-anime {
    width: 40%;
  }

  .success-para {
    font-family: "Courier New", Courier, monospace;
    font-size: 5vh;
    color: #2b8dfc;
    font-weight: bold;
    margin-top: 3%;
  }
}
