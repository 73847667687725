* {
  -webkit-tap-highlight-color: transparent;
}

#datePicker {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
}

#datePicker .unavailable {
  background-color: #ccc;
  cursor: not-allowed;
}

.type-of-reorder-B {
  position: relative;
  width: 90vw;
  height: 82.5vh;
  margin-left: 5%;
  margin-top: 1%;
  background: #ffffff;
  border-radius: 15px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #6759ff;
  margin-bottom: 5%;
}

.type-of-reorder-SpinnerB {
  position: relative;
  width: 90vw;
  height: 82.5vh;
  margin-left: 5%;
  margin-top: 1%;
  background: #ffffff;
  border-radius: 15px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #6759ff;
  margin-bottom: 5%;
}

.type-of-reorder-watch-B {
  position: absolute;
  width: 90vw;
  height: 82.5vh;
  margin-left: 5%;
  margin-top: 6.5%;
  background: #ffffff;
  border-radius: 15px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #6759ff;
}

.type-b-rorder {
  height: 60%;
  width: 92%;
  display: flex;
  padding: 0;
  margin-top: -3%;
}

.userAddress-B {
  height: 95%;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  font-size: 0.7rem;
  flex-wrap: wrap;
  padding-left: 2%;
  margin-top: 1%;
}

.userAddress2-D {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 1%;
  font-size: 0.8rem;
  padding-left: 5%;
  padding-right: 2.5%;
}

.userAddress-B input {
  padding: 2% 0%;
  outline: none;
  width: 90%;
  margin: 0.5% 0%;
}

.userAddress-B textarea {
  padding-bottom: 12%;
  outline: none;
  width: 80%;
  margin-bottom: -5%;
  margin-top: -8%;
}

.userAddress-B textarea::-webkit-scrollbar {
  display: none;
}

.select-option-B {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 30%;
  width: 100%;
}

.select-option-B input {
  margin-top: 2%;
  margin-bottom: 5%;
  height: auto !important;
  width: 35%;
}

.select-time-B {
  display: flex;
  width: 90%;
  justify-content: space-around;
  margin-top: 2.5%;
}

.button-style1 {
  font-family: "Courier New", Courier, monospace;
  color: #6759ff;
  background-color: white;
  padding: 2%;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  cursor: pointer;
  border: 1px solid #6759ff;
}

.button-style2 {
  font-family: "Courier New", Courier, monospace;
  color: #ffffff;
  background-color: #6759ff;
  padding: 2%;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  cursor: pointer;
  border: 1px solid #6759ff;
}

.repeat-order {
  color: #ffffff;
  background-color: #6759ff;
  border: 0;
  padding: 2% 1rem;
  border-radius: 0.2rem;
  cursor: pointer;
  width: 80%;
  font-size: 1.2rem;
}

.repeat-order:active {
  color: #ffffff;
  background-color: #2f24aa;
}

.previous-items-B {
  height: 90%;
  width: 100%;
}

.items-con1-B {
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.items-con2-B {
  height: 90%;
  overflow-y: scroll;
  display: flex;
  justify-content: space-around;
  align-content: flex-start;
  flex-wrap: wrap;
}

.item-con3-B {
  height: 25%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-transform: capitalize;
  flex-wrap: wrap;
  margin: 5% 0%;
  border: 2px solid #6759ff;
  padding: 1%;
  border-radius: 0.3rem;
}

.items-image-B {
  height: 50%;
  width: 20%;
}

.item-con-para-B-D {
  height: 3.25vh !important;
  width: 65% !important;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .select-option-B input {
    width: 35% !important;
    border: 2px solid red;
  }

  .userAddress-B input {
    outline: none;
    width: 90%;
    outline: none;
    border: 0;
  }

  .userAddress-B textarea {
    padding-bottom: 12%;
    outline: none;
    width: 90%;
    border: 0;
  }

  .userAddress-B textarea::-webkit-scrollbar {
    display: none;
  }

  .userAddress-B {
    height: 37.5vh;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    font-size: 0.9rem;
    flex-wrap: wrap;
    padding-left: 5%;
    margin-top: 1%;
    margin-left: 5%;
    border: 2px solid transparent !important;
  }

  .item-con3-B img {
    height: 100%;
    width: 25%;
  }

  .item-con-para-B-D {
    height: 2vh !important;
    width: 90% !important;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .item-con3-B {
    height: 10vh !important;
  }

  .item-con3-B img {
    height: 30%;
  }

  .item-con3-B p {
    width: 100%;
    font-size: 0.5rem;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .userAddress2-D {
    width: 100%;
  }

  .type-of-reorder-B {
    position: relative;
    height: auto;
    margin-bottom: 5%;
  }

  .type-b-rorder {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .userAddress input {
    margin: 5% 0%;
    border: none;
  }

  .userAddress textarea {
    padding-bottom: 12%;
    outline: none;
    width: 80%;
    margin-top: 2%;
    margin-bottom: 8%;
    border: none;
  }

  .userAddress {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    font-size: 0.8rem;
    flex-wrap: wrap;
    padding-left: 10%;
    margin-top: 10%;
  }

  .userAddress2 {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 2.5%;
    font-size: 0.8rem;
    padding-left: 5%;
    padding-right: 2.5%;
    border: 0.1rem dashed #22222250;
    border-left: hidden;
    border-top: hidden;
    border-right: hidden;
    padding-bottom: 8%;
  }

  .userAddress2 input {
    margin-bottom: 8%;
    padding: 1% 3%;
    font-size: 0.9rem;
  }

  .type-of-reorder {
    position: relative;
    height: auto;
    width: 85%;
    margin-top: 5%;
    margin-left: 8%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    padding-top: 4%;
    padding-bottom: 2%;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 8%;
    padding-bottom: 8%;
    color: #9359ff;
    margin-bottom: 5%;
  }

  .previous-items {
    height: auto;
    width: 100%;
  }

  .items-con1 {
    padding: 2% 0%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .items-con2 {
    height: 40vh;
    overflow-y: scroll;
    margin-bottom: 5%;
    margin-top: 2%;
  }

  .item-con3 {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-transform: capitalize;
    margin: 5% 0%;
  }

  .items-image {
    height: 100%;
    width: 8%;
  }

  .type-of-reorder-watch {
    position: relative;
    height: 30vh;
    width: 85%;
    margin-top: 5%;
    margin-left: 8%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    padding-top: 4%;
    padding-bottom: 2%;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 8%;
    padding-bottom: 8%;
    color: #9359ff;
  }
  .repeat-order {
    align-self: center;
    margin-right: 10%;
    margin-top: 5%;
  }

  .item-con3-B p {
    font-size: 0.75rem !important;
  }
  .repeat-order {
    position: relative !important;
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .type-of-reorder-B {
    height: 35vh;
  }
  .item-con3-B {
    height: 35%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }
  .userAddress2-D {
    padding-left: 2%;
    margin-left: -5%;
    margin-right: -3%;
  }
  .userAddress-B {
    width: 30%;
  }
  .items-image-B {
    height: 40%;
    width: 25%;
  }
  .items-con2-B {
    font-size: 0.7rem;
  }
  .repeat-order {
    width: 100%;
  }
  .button-style1 {
    font-size: 0.55rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .type-of-reorder-B {
    height: 60vh;
  }
  .item-con3-B {
    height: 12vh;
  }

  .item-con3-B img {
    height: 30%;
  }

  .item-con-para-B-D {
    font-size: 0.8rem !important;
    height: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .items-con2-B {
    height: 29vh;
  }
  .select-time-B {
    width: 100% !important;
  }
  .repeat-order {
    width: 100%;
    margin-bottom: 4%;
  }
}
