@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;900&display=swap");

*::selection {
  background-color: transparent;
}

/**NewCSS**/

.alertClass {
  position: absolute;
  background-color: #ffffff;
  z-index: 2;
  left: 5%;
  right: 5%;
  padding: 3%;
  border-radius: 0.5rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: var(--def-color);
  border: 2px solid var(--def-color);
}

.alertClass > div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5%;
}

.alertClass > div button {
  padding: 1% 2%;
  background-color: var(--def-color);
  color: #ffffff;
  border: 1px solid var(--def-color);
  border-radius: 0.25rem;
  cursor: pointer;
}

.alertClass > div button:first-child {
  padding: 1% 2%;
  background-color: #ffffff;
  color: var(--def-color);
  border: 1px solid var(--def-color);
  border-radius: 0.25rem;
  cursor: pointer;
}

.addClothes-A {
  position: absolute;
  background-color: #ffffff;
  height: 60%;
  right: 6%;
  top: 27.5%;
  padding: 1%;
  width: 45%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.counter-total-value-A {
  font-size: 1rem;
  color: #6759ff;
  width: 15%;
  left: 73.5%;
  text-align: center;
  position: absolute;
  font-weight: normal;
  top: 6.2%;
  height: auto;
}

.counter-buttons-container-A {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  display: inline;
  left: 5%;
  position: absolute;
  top: 6%;
  height: auto;
}

.button-pick-A {
  height: auto;
  margin-top: 1%;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 2%;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  font-weight: normal;
  background-color: #6759ff;
  border-color: #6759ff;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  cursor: pointer;
}

.pick-button-A {
  margin-top: 1%;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 2%;
  border-style: solid;
  border-color: #5647fe;
  border-width: 2px;
  border-radius: 5px;
  color: #5647fe;
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  font-weight: normal;
  background-color: transparent;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  height: auto;
  cursor: pointer;
}

.pick-button-A:hover {
  background-color: #6759ff;
  color: #fff;
}

.clothes-add-A {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2%;
  margin-left: 2.5%;
}

.set-counter-total1-A {
  font-size: 1rem;
  color: #6759ff;
  width: 15%;
  left: 79%;
  text-align: center;
  position: absolute;
  top: 6.8%;
}

.counter-total-price-A {
  font-size: 1rem;
  color: #6759ff;
  width: 15%;
  left: 82%;
  text-align: center;
  position: absolute;
  font-weight: normal;
  top: 6.5%;
}

.items-over-A {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 30vh;
  margin-top: 12.5%;
  margin-right: 5%;
  border: 2px solid transparent;
  overflow-x: hidden;
}

.set-counter-A {
  height: 40%;
  width: 27%;
  background-color: transparent;
  border-color: #6759ff;
  border-style: solid;
  border-width: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  margin-right: 1vw;
  margin-left: 1vw;
  padding-top: 2%;
  padding-bottom: 1%;
  position: relative;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.set-counter-icon-A {
  color: #6759ff;
  height: 60%;
  width: 23%;
  margin-top: -5%;
}

.set-counter-button-A {
  width: 20%;
  background-color: transparent;
  border-width: 0px;
  color: #6759ff;
  font-size: 1.5vw;
  font-weight: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  margin-bottom: 1vh;
  cursor: pointer;
}

.set-counter-para-A {
  font-size: 1rem;
  color: #6759ff;
  margin-top: -4%;
  width: 15%;
  text-align: center;
}

.set-counter-name-A {
  font-family: "Poppins", sans-serif;
  font-size: 0.7rem;
  color: #6759ff;
  position: absolute;
  font-weight: normal;
  left: 3%;
  bottom: 2.5%;
}

.set-counter-amount-A {
  font-family: "Poppins", sans-serif;
  font-size: 0.65rem;
  color: #6759ff;
  position: absolute;
  font-weight: normal;
  left: 3%;
  top: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.set-counter-price-A {
  display: none;
}

.set-couter-price-icon3-A {
  font-size: 0.8rem;
  color: #6759ff;
  width: 15%;
  left: 65%;
  text-align: center;
  position: absolute;
  top: 3.5%;
}

.set-couter-price-price3-A {
  font-size: 0.8rem;
  color: #6759ff;
  width: 15%;
  left: 76%;
  text-align: center;
  position: absolute;
  top: 1.25%;
}

.wash-button-A {
  width: 80%;
  background-color: #6759ff;
  border-radius: 10px;
  color: #fff;
  padding: 3%;
  border-width: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5%;
  cursor: pointer;
}

.wash-clothes-icon-A {
  margin-left: 1%;
  font-weight: normal;
  cursor: pointer;
}
.wash-button-A:hover {
  background-color: #5647fe;
  cursor: pointer;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .addClothes-A {
    height: 52vh;
    width: 90%;
    position: relative;
    margin-top: 10%;
    margin-left: 11.5% !important;
    margin-bottom: 10%;
  }
  .counter-buttons-container-A {
    width: 100%;
    margin-top: 3%;
    left: 0;
  }
  .button-pick-A {
    font-size: 0.6rem;
  }

  .pick-button-A {
    font-size: 0.6rem;
  }
  .counter-total-value-A {
    top: 3%;
    left: 65% !important;
  }
  .counter-total-price-A {
    top: 3%;
  }
  .set-counter-total1-A {
    top: 3.5%;
    left: 76%;
  }
  .set-counter-A {
    width: 45%;
    height: 30%;
  }
  .set-counter-button-A {
    font-size: 1.25rem;
    margin-top: 1.5%;
  }
  .items-over-A {
    margin-top: 18%;
    margin-bottom: 2.5%;
    height: 30vh !important;
  }
  .set-counter-icon-A {
    height: 50%;
  }
  .set-counter-name-A {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) and (orientation: portrait) {
  .items-over-A {
    height: 15vh;
    width: 100%;
  }
  .counter-buttons-container-A {
    width: 75%;
  }
  .button-pick-A {
    font-size: 0.55rem;
  }
  .pick-button-A {
    font-size: 0.55rem;
  }

  .counter-total-value-A {
    font-size: 0.6rem;
    left: 72%;
  }
  .counter-total-price-A {
    font-size: 0.6rem;
  }
  .set-counter-total1-A {
    font-size: 0.6rem;
  }
  .set-counter-A {
    width: 28%;
  }
  .set-counter-icon-A {
    height: 45%;
  }
  .set-counter-button-A {
    margin-top: 5%;
    font-size: 1.2rem;
  }
  .set-counter-name-A {
    font-size: 0.5rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) and (orientation: portrait) {
  .items-over-A {
    height: 15vh;
    margin-top: 15%;
    margin-bottom: -5%;
    width: 100%;
  }
  .counter-buttons-container-A {
    width: 75%;
  }
  .button-pick-A {
    font-size: 0.65rem;
  }
  .pick-button-A {
    font-size: 0.65rem;
  }

  .counter-total-value-A {
    font-size: 0.8rem;
    left: 72%;
  }
  .counter-total-price-A {
    font-size: 0.8rem;
  }
  .set-counter-total1-A {
    font-size: 0.8rem;
  }
  .set-counter-A {
    height: 35%;
    width: 28%;
  }
  .set-counter-icon-A {
    height: 45%;
  }
  .set-counter-button-A {
    margin-top: 5%;
    font-size: 1.2rem;
  }
  .set-counter-name-A {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: landscape) {
  .items-over-A {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 22.5vh;
    margin-top: 15%;
    margin-bottom: -5%;
  }
  .set-counter-A {
    height: 35%;
    width: 27%;
    background-color: transparent;
    border-color: #6759ff;
    border-style: solid;
    border-width: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    margin-right: 1vw;
    margin-left: 1vw;
    padding-top: 2%;
    padding-bottom: 1%;
    position: relative;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
  .counter-buttons-container-A {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    display: inline;
    left: 2.5%;
    position: absolute;
    top: 6%;
    height: auto;
  }
  .counter-total-value-A {
    font-size: 0.9rem;
    color: #6759ff;
    width: 15%;
    left: 72.5%;
    text-align: center;
    position: absolute;
    font-weight: normal;
    top: 6.2%;
    height: auto;
  }
  .counter-total-price-A {
    font-size: 0.9rem;
    color: #6759ff;
    width: 15%;
    left: 82%;
    text-align: center;
    position: absolute;
    font-weight: normal;
    top: 6.5%;
  }

  .set-counter-amount-A {
    margin-top: -1%;
    font-size: 0.6rem;
  }

  .set-couter-price-price3-A {
    font-size: 0.75rem;
  }
  .set-counter-button-A {
    margin-top: 4%;
  }

  .set-counter-icon-A {
    height: 48%;
  }
  .set-counter-name-A {
    font-size: 0.5rem;
  }
  .set-couter-price-icon3-A {
    margin-top: -1%;
    font-size: 0.8rem;
  }
  .set-counter-amount-A {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/**NewCss*/

@media screen and (max-width: 420px) and (orientation: portrait) {
  .set-counter-icon-A {
    height: 45%;
    width: 20%;
  }
}

@media screen and (max-width: 350px) and (orientation: portrait) {
  .button-pick-A {
    font-size: 0.5rem;
  }

  .pick-button-A {
    font-size: 0.5rem;
    margin-top: 5%;
  }

  .items-over-A {
    margin-top: 25%;
    height: 35vh !important;
  }

  .set-counter-A > p {
    font-size: 0.65rem;
  }

  .set-couter-price-icon3-A {
    font-size: 0.65rem;
  }

  .addClothes-A {
    height: 65vh !important;
  }

  .wash-button-A {
    margin-top: 10% !important;
  }
  .set-counter-icon-A {
    height: 40%;
  }

  .set-counter-para-A {
    font-size: 0.8rem !important;
  }
}

@media screen and (max-width: 376px) and (orientation: portrait) {
  .set-counter-amount-A {
    top: 0% !important;
  }
  .set-counter-para-A {
    font-size: 0.8rem !important;
  }
}
