@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800;900&display=swap");

.about-us {
  height: 100%;
  width: 100%;
}
.about {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.about h2 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  align-self: center;
  margin-top: 8%;
}

.about h1 {
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  align-self: center;
  margin-top: 3%;
}

.about p {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  align-self: center;
  width: 60%;
  text-align: center;
  margin-top: 2%;
}

.about span {
  color: #6759ff;
}

.about > div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 5%;
}

.about > div > img {
  width: 30%;
  margin-left: 10%;
}

.about > div > div {
  height: auto;
  width: 70%;
  padding-left: 10%;
}

.about > div > div > p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  width: 85%;
  text-align: start;
  margin-top: 5%;
  margin-bottom: 5%;
}

.promise {
  height: auto;
  width: 100%;
}

.promise > div {
  height: auto;
  width: 30% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 !important;
}

.promise > div > img {
  height: auto;
  width: 30%;
}

.promise > div h3 {
  font-size: 1.5rem;
  margin-top: 5%;
}

.promise > div p {
  text-align: center !important;
}

.aim {
  height: 30%;
  width: 100%;
  background-color: #6759ff33;
  padding: 2%;
}

.aim > div:first-child {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.aim > div:last-child {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.aim-img1 {
  width: 18% !important;
}

.aim-img2 {
  width: 18% !important;
}

.aim > div > div {
  height: auto;
  width: 70%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.aim > div > div h3 {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: flex-start;
}

#aim-para1 {
  text-align: start;
  width: 100%;
}

#aim-para2 {
  text-align: start;
  width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .about > div > div > p {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .about > h2 {
    font-size: 2.6rem;
    text-align: center;
  }

  .about > p {
    width: 90%;
  }

  .about > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .about > div > img {
    width: 90%;
    margin: 0%;
  }

  .about > div > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0%;
  }

  .promise > div {
    width: 100% !important;
    margin-top: 5%;
  }

  .aim > div {
    width: 100% !important;
  }

  .aim-img1 {
    width: 40% !important;
    margin: 5% 0%;
  }

  .aim-img2 {
    width: 40% !important;
    margin: 5% 0%;
  }
}
