@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800;900&display=swap");

@keyframes animate-reorder {
  0% {
    color: green;
  }
  100% {
    color: green;
    transform: translate(-5%);
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

.alertClassB {
  position: absolute;
  background-color: #ffffff;
  z-index: 2;
  left: 5%;
  right: 5%;
  padding: 3%;
  border-radius: 0.5rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: var(--def-color);
  border: 2px solid #6759ff;
}

.alertClassB > div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5%;
}

.alertClassB > div button {
  padding: 1% 2%;
  background-color: var(--def-color);
  color: #ffffff;
  border: 1px solid var(--def-color);
  border-radius: 0.25rem;
  cursor: pointer;
}

.alertClassB > div button:first-child {
  padding: 1% 2%;
  background-color: #ffffff;
  color: var(--def-color);
  border: 1px solid var(--def-color);
  border-radius: 0.25rem;
  cursor: pointer;
}

.alert {
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
  left: 20%;
  right: 20%;
  padding: 2%;
  border-radius: 0.5rem;
  margin-top: -5%;
  color: #6759ff;
  text-align: center;
  border: 2px solid #6759ff;
}

.alert div {
  margin-top: 5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.alert div button:first-child {
  background-color: #6759ff;
  border: 0;
  color: #ffffff;
  padding: 0.5% 2%;
  border-radius: 0.25rem;
  cursor: pointer;
}

.alert div button:last-child {
  background-color: #6759ff;
  border: 0;
  color: #ffffff;
  padding: 0.5% 2%;
  border-radius: 0.25rem;
  cursor: pointer;
}

.reorder-animate-B {
  position: absolute;
  right: 38px;
  top: 6.5px;
  overflow: visible;
  font-size: 0.6rem;
  animation: animate-reorder 0.5s infinite alternate;
}

.reorder-button-B {
  position: absolute;
  right: 15px;
  top: 5px;
  height: 20px;
  width: 20px;
  z-index: 5;
  cursor: pointer;
}

.type-of-wash-continue-andAddItems {
  position: relative;
  width: 90vw;
  height: 90%;
  margin-left: 5%;
  margin-top: 1%;
  margin-bottom: 2.5%;
  background: #ffffff;
  border-radius: 15px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.type-of-head {
  position: absolute;
  font-family: "Poppins", sans-serif;
  top: 8%;
  margin-bottom: -5%;
  font-weight: 1000;
}
.type-of-button-addClothes {
  border-style: solid;
  border-color: #6759ff;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  height: 100%;
  margin: 0.25rem 5%;
  background-color: #ffffff;
  color: #6759ff;
  font-weight: bold;
}

.type-of-image {
  height: 45%;
  width: 45%;
}
.type-of-button-addClothes1 {
  border-style: solid;
  border-color: green;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  height: 100%;
  background-color: #ffffff;
  color: #6759ff;
  font-weight: bold;
  margin: 0.25rem 5%;
}

.type-of-wash-continue-addClothes1 {
  position: relative;
  background-color: #6759ff;
  border-radius: 0.25rem;
  color: #fff;
  border-width: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  font-weight: bold;
  margin-right: 5%;
  margin-top: 6%;
  padding: 1% 2.5%;
}

.type-of-wash-continue-addClothes1 > span {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 0.65rem;
}

.type-of-wash-continue-addClothes2 {
  position: relative;
  background-color: #6759ff;
  border-radius: 0.25rem;
  color: #fff;
  padding: 1.25% 1.25%;
  border-width: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  font-weight: bold;
  margin-right: 5%;
  width: 28%;
  margin-top: 5.73%;
  cursor: pointer;
}

.type-of-wash-continue-addClothes2 > span {
  width: 50%;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 0.6rem;
  margin-top: 1.5%;
  text-align: start;
  font-weight: bold;
}

.type-of-wash-con-addClothes {
  height: auto;
  width: 11%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.addCloths-con {
  height: 90% !important;
  width: 85%;
  align-self: center;
  display: flex;
  margin-top: -1%;
  margin-bottom: 1.5%;
}

.typeof-clothes-toadd {
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .alert {
    left: 5%;
    right: 5%;
    padding: 3%;
  }

  .alert div button:first-child {
    padding: 1% 2%;
  }

  .alert div button:last-child {
    padding: 1% 2%;
  }

  .reorder-button-B {
    position: absolute;
    right: 8px;
    top: 10px;
    height: 25px;
    width: 25px;
    z-index: 5;
    cursor: pointer;
  }

  .typeof-clothes-toadd {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .type-of-button-addClothes1 {
    height: 10vh;
    width: 100vw;
    font-size: 0.65rem;
    border-radius: 0.5rem;
  }

  .type-of-button-addClothes {
    height: 10vh;
    width: 100vw;
    font-size: 0.65rem;
    border-radius: 0.5rem;
  }

  .type-of-button-addClothes1 > img {
    height: 35%;
    width: 50%;
    margin: 0;
    padding: 0;
    margin-bottom: -20%;
  }

  .type-of-button-addClothes > img {
    height: 35%;
    width: 50%;
    margin: 0;
    padding: 0;
    margin-bottom: -20%;
  }

  .type-of-wash-con-addClothes {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .addCloths-con {
    height: auto;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
  }

  .type-of-wash-continue-andAddItems {
    position: relative;
    width: 90vw;
    height: auto;
    margin-left: 5%;
    margin-top: 6.5%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 5%;
  }

  .type-of-head {
    color: #6759ff;
    font-size: 5vw;
    position: absolute;
    font-family: "Poppins", sans-serif;
    top: 8%;
    margin-bottom: -5%;
    font-weight: 1000;
  }
  .type-of-wash {
    position: relative;
    height: 40vh;
    width: 85%;
    margin-top: 5%;
    margin-left: 8%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    padding-top: 4%;
    padding-bottom: 2%;
    font-family: "Poppins", sans-serif;
    padding-top: 8%;
    padding-bottom: 8%;
    color: #9359ff;
  }

  .type-of-button {
    border-style: solid;
    border-color: #6759ff;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 25%;
    border-radius: 10px;
    height: 9.8vh;
    background-color: #ffffff;
    color: #6759ff;
    font-weight: bold;
    font-size: 0.6rem;
  }

  .type-of-image {
    height: 45%;
    width: 45%;
  }
  .type-of-button1 {
    border-style: solid;
    border-color: green;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 25%;
    border-radius: 9.5px;
    height: 9.8vh;
    color: #6759ff;
    font-weight: bold;
    font-size: 2.5vw;
    background-color: #ffffff;
  }
  .type-of-wash-continue {
    position: absolute;
    width: 80%;
    background-color: #6759ff;
    border-radius: 10px;
    color: #fff;
    padding: 3%;
    border-width: 0px;
    font-family: "Poppins", sans-serif;
    font-size: 3.5vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5%;
    bottom: 2%;
  }
  .type-of-wash-con {
    margin-top: -30%;
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .reorder-animate-B {
    position: absolute;
    right: 38px;
    top: 11px;
    overflow: visible;
    font-size: 0.7rem;
    animation: animate-reorder 0.5s infinite alternate;
  }
}

@media screen and (max-width: 600px) {
  .type-of-wash-continue-addClothes1 {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    font-size: 0.7rem;
    padding: 1% 2.5%;
  }

  .type-of-wash-continue-addClothes2 {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    width: 70% !important;
    padding: 1% 2.5% !important;
    cursor: pointer;
    font-size: 0.7rem;
  }
  .type-of-wash-continue-addClothes2 span {
    font-size: 0.6rem !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
  .alert {
    left: 5%;
    right: 5%;
    padding: 3%;
  }

  .alert div button:first-child {
    padding: 1% 2%;
  }

  .alert div button:last-child {
    padding: 1% 2%;
  }

  .type-of-wash-continue-andAddItems {
    height: 70vh !important;
  }
  .addCloths-con {
    height: 60% !important;
    overflow: hidden;
  }

  .type-of-wash-con-addClothes {
    width: 15%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1024px) and (orientation: landscape) {
  .type-of-wash-con-addClothes {
    width: 15%;
  }
  .set-counter-b {
    height: 40% !important;
    width: 28% !important;
  }
  .set-counter-icon {
    height: 50% !important;
    width: 30% !important;
  }
  .reorder-animate-B {
    top: 0.7%;
  }
  .alert {
    left: 5%;
    right: 5%;
    padding: 3%;
  }

  .alert div button:first-child {
    padding: 1% 2%;
  }

  .alert div button:last-child {
    padding: 1% 2%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .alert {
    left: 5%;
    right: 5%;
    padding: 3%;
  }

  .alert div button:first-child {
    padding: 1% 2%;
  }

  .alert div button:last-child {
    padding: 1% 2%;
  }

  .type-of-wash-con-addClothes {
    height: 30vh;
    width: 20%;
  }
  .type-of-button-addClothes1 {
    font-size: 1rem !important;
  }
  .type-of-button-addClothes2 {
    font-size: 1rem !important;
  }
  .pick-button-b {
    font-size: 0.8rem !important;
  }
  .button-pick-b {
    font-size: 0.8rem !important;
  }
  .set-counter-b {
    height: 25% !important;
    width: 28% !important;
  }
  .set-counter-b p {
    font-size: 1rem;
  }
  .set-counter-icon {
    height: 45% !important;
    width: 30% !important;
  }
  .set-couter-price-icon3 {
    font-size: 0.5rem;
    position: absolute;
    top: -2% !important;
  }

  .set-counter-button {
    font-size: 1.5rem !important;
    margin-top: 5%;
  }
  .set-counter-para {
    font-size: 1.5rem !important;
  }

  .reorder-animate-B {
    top: 0.8%;
  }
  .reorder-animate-B {
    top: 0.5%;
  }
  .typeof-clothes-toadd {
    height: 35vh;
  }
  .items-over-b {
    width: 110% !important;
  }
  .type-of-wash-continue-addClothes1 {
    bottom: 20%;
  }
  .type-of-wash-continue-addClothes2 {
    bottom: 20%;
    width: 45%;
    cursor: pointer;
  }
  .counter-buttons-container-b {
    width: 28% !important;
  }
  .type-of-wash-continue-addClothes1 {
    bottom: 11%;
  }
  .type-of-wash-continue-addClothes2 {
    width: 55% !important;
    bottom: 11%;
    cursor: pointer;
  }
  .set-counter-b {
    width: 45% !important;
  }
  .reorder-animate-B {
    top: 0.1%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  .alert {
    left: 5%;
    right: 5%;
    padding: 3%;
  }

  .alert div button:first-child {
    padding: 1% 2%;
  }

  .alert div button:last-child {
    padding: 1% 2%;
  }

  .type-of-wash-con-addClothes {
    height: 30vh;
    width: 20%;
  }
  .type-of-button-addClothes1 {
    font-size: 1rem !important;
  }
  .type-of-button-addClothes2 {
    font-size: 1rem !important;
  }
  .pick-button-b {
    font-size: 0.8rem !important;
  }
  .button-pick-b {
    font-size: 0.8rem !important;
  }
  .set-counter-b {
    height: 25% !important;
    width: 28% !important;
  }
  .set-counter-b p {
    font-size: 1rem;
  }
  .set-counter-icon {
    height: 45% !important;
    width: 30% !important;
  }
  .set-couter-price-icon3 {
    font-size: 0.5rem;
    position: absolute;
    top: -2% !important;
  }

  .set-counter-button {
    font-size: 1.5rem !important;
    margin-top: 5%;
  }
  .set-counter-para {
    font-size: 1.5rem !important;
  }

  .reorder-animate-B {
    top: 0.8%;
  }
  .reorder-animate-B {
    top: 0.5%;
  }
  .typeof-clothes-toadd {
    height: 35vh;
  }
  .items-over-b {
    width: 110% !important;
  }
  .type-of-wash-continue-addClothes1 {
    bottom: 20%;
  }
  .type-of-wash-continue-addClothes2 {
    bottom: 20%;
    width: 45%;
    cursor: pointer;
  }
}
