@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;900&display=swap");

:root {
  --def-color: #6759ff;
  --def-font: "Poppins", sans-serif;
}

/**Offers Css*/

.offers-background {
  height: auto;
  width: 100%;
}

.offers-background h1 {
  font-family: var(--def-font);
  font-size: 3rem;
  text-align: center;
  color: var(--def-color);
  margin-top: 1.5%;
}

.offers-background div {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.offers-background > div img {
  margin-top: 2.5%;
  width: 30%;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-bottom: 2.5%;
}

/**Offers Css*/

/*Features Css*/

.feature-head-text {
  font-family: var(--def-font);
  text-align: center;
  color: #222222;
  font-size: 3rem;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.feature-head-text span {
  font-family: var(--def-font);
  text-align: center;
  color: var(--def-color);
}

.feature-background {
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.features-boxes {
  height: 100%;
  padding: 2%;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  word-wrap: break-word;
  margin: 1%;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.5s linear;
}

.features-boxes:hover {
  transform: scale(1.1);
}

.features-boxes p {
  font-family: "Poppins", sans-serif;
  margin: 2% 0%;
  font-weight: bold;
  max-width: 100%;
}

.features-boxes p:last-child {
  font-family: "Poppins", sans-serif;
  margin: 2% 0%;
  font-weight: normal;
  max-width: 100%;
  font-size: 0.7rem;
}

.features-boxes img {
  height: 15% !important;
  width: 15% !important;
}

.fes1 {
  background-color: #2d69f0;
}

.fes2 {
  background-color: #dd246e;
}

.fes3 {
  background-color: #6759ff;
}

.fes4 {
  background-color: #0cae74;
}

.show-feature {
  display: inline;
}

/*Fearures Css*/

/**Iphone Css**/

.phones-con1 {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 8%;
}

.phones-con1 > img {
  width: 40%;
  margin: 0%;
  border-radius: 0.5rem;
}

.phones-con2 {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 8%;
}

.phones-con2 > img {
  width: 30%;
  margin: 0%;

  order: 2;
  border-radius: 0.5rem;
}

.matter-phone {
  width: 40%;
  margin: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.first-con-matter p:first-child {
  font-family: var(--def-font);
  color: var(--def-color);
  font-size: 1rem;
  font-weight: 700;
}

.first-con-matter p:last-child {
  font-family: var(--def-font);
  color: #222222;
  font-size: 2.5rem;
  font-weight: 700;
}

.second-con-matter {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.second-con-matter > div {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.second-con-matter > div img {
  width: 6%;
}

.second-con-matter > div p {
  font-family: var(--def-font);
  color: #222222;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 2%;
}

.second-con-matter > p {
  font-family: var(--def-font);
  color: #a3a3a3;
  font-size: 0.85rem;
  margin: 2% 0%;
}

/**Iphone Css**/

/**All Iphones css**/

.all-phones-con-main-background {
  height: auto;
  width: 100%;
  margin-top: 20%;
  background-color: #f0efff;
  padding: 5%;
  overflow: hidden;
  padding-bottom: 30%;
  position: relative;
  z-index: 0;
}

.phones-all-con {
  height: auto;
  width: 100%;
  z-index: 2;
}

.phones-all-con p {
  font-family: var(--def-font);
  color: #222222;
  max-width: 50%;
  z-index: 2;
}

.phones-all-con p:first-child {
  font-family: var(--def-font);
  color: grey;
  z-index: 2;
}

.phones-all-con h1 {
  font-family: var(--def-font);
  font-size: 2rem;
  font-weight: 900;
  z-index: 2;
}

.phones-all-con > div {
  max-width: 35%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
  z-index: 2;
}

.phones-all-con > div button {
  height: 100%;
  width: 45%;
  border: 0;
  border-radius: 0.3rem;
  z-index: 2;
}

.phones-all-con > div > button img {
  object-fit: cover;
  border-radius: 0.3rem;
  cursor: pointer;
  z-index: 2;
}

.alliphone-image {
  position: absolute;
  top: 0;
  z-index: -1;
  right: 0;
  width: 100%;
  object-fit: contain;
}

/**All Iphones css**/

@media screen and (max-width: 600px) {
  /**Offers Css*/

  .offers-background h1 {
    font-size: 2.5rem;
  }

  .offers-background div {
    flex-direction: column;
  }
  .offers-background h1 {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .offers-background > div img {
    width: 90%;
  }
  /**Offers Css*/

  /**Features Css*/

  .feature-head-text {
    font-family: var(--def-font);
    text-align: center;
    color: #222222;
    font-size: 2.5rem;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .features-boxes {
    margin: 7%;
    padding: 5%;
  }

  .features-boxes p {
    font-size: 1.25rem;
  }

  .features-boxes p:last-child {
    font-size: 1rem;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .show-feature {
    display: none;
  }

  /**Features Css**/

  /**Iphone css**/

  .phones-con1 {
    flex-direction: column;
    margin-top: 10%;
  }

  .phones-con1 > img {
    width: 80%;
    margin-bottom: 8%;
  }

  .phones-con2 {
    flex-direction: column;
  }

  .phones-con2 > img {
    width: 80%;
    order: 0 !important;
    margin-top: 8%;
    margin-bottom: 8%;
  }

  .matter-phone {
    width: 80%;
  }

  .first-con-matter p:last-child {
    font-size: 1.7rem;
    margin: 3% 0%;
  }
  /**Iphone**/
  .all-phones-con-main-background {
    padding-bottom: 85%;
  }
  .phones-all-con {
    width: 100%;
  }

  .phones-all-con h1 {
    width: 100%;
    font-size: 2rem;
  }

  .phones-all-con p {
    max-width: 100%;
    font-size: 0.8rem;
  }

  .phones-all-con > div {
    max-width: 90%;
  }

  .phones-all-con > div button {
    width: 45%;
    padding: 0;
    background-color: transparent;
    margin-top: 2%;
  }

  .phones-all-con > div button img {
    width: 100%;
    object-fit: cover;
    margin: 0;
  }

  .alliphone-image {
    position: absolute;
    top: 38%;
    right: -45%;
    transform: scale(2);
  }

  /**Iphone**/
}

@media screen and (min-width: 1025px) and (max-width: 1366px) and (orientation: portrait) {
  .features-boxes {
    padding: 1.25%;
  }
  .features-boxes p {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  .phones-all-con {
    margin-left: -2%;
    margin-top: -2.5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .phones-all-con {
    margin-left: -2.5%;
  }

  .phones-all-con h1 {
    font-size: 1.3rem;
  }

  .phones-all-con p:last-child {
    font-size: 0.7rem;
  }
  .phones-all-con > div button img {
    width: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: landscape) {
  .phones-all-con > div button img {
    width: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: portrait) {
  .dots {
    display: none;
  }

  .features-boxes {
    padding: 1.5%;
    height: 80%;
  }
  .features-boxes p {
    font-size: 1rem;
  }

  .features-boxes p:last-child {
    font-size: 0.5rem;
  }

  /**Iphone css**/

  .phones-con1 {
    flex-direction: column;
  }

  .phones-con1 > img {
    width: 80%;
    margin-bottom: 8%;
  }

  .phones-con2 {
    flex-direction: column;
  }

  .phones-con2 > img {
    width: 80%;
    order: 0 !important;
    margin-top: 8%;
    margin-bottom: 8%;
  }

  .matter-phone {
    width: 80%;
  }

  .phones-all-con p {
    font-family: var(--def-font);
    color: grey;
    z-index: 2;
    font-size: 0.7rem;
  }

  /**Iphone css**/
}

@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: landscape) {
  .features-boxes {
    padding: 1.5%;
    height: 80%;
  }
  .features-boxes p {
    font-size: 1rem;
  }

  .features-boxes p:last-child {
    font-size: 0.8rem;
  }
  .dots {
    display: none;
  }
  /**Features**/

  /**Iphone**/

  .phones-con1 > img {
    width: 45%;
  }

  .phones-con2 > img {
    width: 40%;
  }

  .first-con-matter p:last-child {
    font-size: 1.5rem;
    margin: 3% 0%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  .phones-con1 > img {
    width: 50%;
  }

  .phones-con2 > img {
    width: 50%;
  }
}
