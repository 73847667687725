* {
  -webkit-tap-highlight-color: transparent;
}

.success-total-con-B {
  position: relative;
  width: 90vw;
  height: 84vh;
  margin-left: 5%;
  margin-top: 1%;
  margin-bottom: 5%;
  background: #ffffff;
  border-radius: 15px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.success-anime-BC {
  margin-top: -5%;
  width: 20%;
  align-self: center;
}

.success-para-BC {
  bottom: 10%;
  position: absolute;
  font-family: "Courier New", Courier, monospace;
  font-size: 4rem;
  color: #2b8dfc;
  font-weight: bold;
  align-self: center;
}

@media screen and (max-width: 576px) {
  .success-total-con-B {
    position: relative;
    height: 50vh;
    margin-bottom: 5%;
  }

  .success-anime-BC {
    margin-top: -40%;
    width: 70% !important;
    align-self: center;
  }

  .success-para-BC {
    font-size: 5em !important;
    align-self: center;
  }

  .success-total-con {
    position: relative;
    width: 85%;
    margin-top: 5%;
    margin-left: 8%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
    padding-top: 20%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 20%;
  }

  .success-para-BC {
    font-family: "Courier New", Courier, monospace;
    font-size: 5vh;
    color: #2b8dfc;
    font-weight: bold;
    margin-top: 3%;
    text-align: center;
  }
}
