*::selection {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6759ff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6759ff;
}

.filterButton {
  font-size: 0.8rem;
  background-color: #fff;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  margin-left: 1rem;
  color: #6759ff;
  border: 0.1rem #6759ff solid;
  border-radius: 0.3rem;
}

.filterButton2 {
  font-size: 0.8rem;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  background-color: #6759ff;
  margin-left: 1rem;
  color: #fff;
  border: 0.1rem #fff solid;
  border-radius: 0.3rem;
}

.calender3 {
  position: absolute;
  height: auto;
  width: 23vw;
  top: 50vh;
  background-color: #fff;
  z-index: 5;
  border-radius: 1rem;
  left: 53vw;
  border-color: #6759ff;
  border-width: 2px;
}

@media screen and (min-width: 992px) {
  .order-body-header4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    width: 100%;
  }

  .order-body-header4 h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3vw;
    line-height: 10vw;
  }

  .noresult {
    margin-top: 10%;
    height: 50%;
    width: 30%;
  }
  .total-dashboard-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-family: Arial, Helvetica, sans-serif;
  }

  /* Aside section css*/

  .aside-board {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .aside-logo {
    height: 7%;
    width: 70%;
    margin-top: 10%;
  }

  .section-in-aside-dashboard {
    height: auto;
    margin-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .each-section-1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 8vh;
    width: 80%;
    padding-top: 4.5%;
    border-radius: 10px;
    background-color: #6759ff;
    color: #fff;
    cursor: pointer;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .each-section-1 h5 {
    font-size: 1.3rem;
    padding-bottom: 4%;
  }

  .each-section-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 8vh;
    width: 80%;
    padding-top: 4.5%;
    border-radius: 10px;
    background-color: #ffffff;
    color: #53545c;
    cursor: pointer;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .each-section-2 h5 {
    font-size: 1rem;
    padding-bottom: 4.25%;
  }

  .each-section-logo {
    height: 65%;
    width: 13%;
    margin-bottom: 5%;
    margin-left: 8%;
    margin-right: 8%;
  }

  /* Header section css*/

  .dashboard-header {
    height: 13%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
  }

  .header-select {
    height: 4vh;
    width: auto;
    margin: 0% 5%;
    background-color: #e8e6ff;
    border-color: transparent;
    border-radius: 5px;
  }

  .header-select-button {
    height: 4vh;
    width: 10vw;
    margin-right: 5%;
    background-color: #6759ff;
    border-color: transparent;
    border-radius: 5px;
    color: #ffffff;
  }

  .header-body {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  /* Dashboard css*/

  .dashboard-body {
    height: 87%;
    width: 100%;
    background-color: #e8e6ff;
    padding: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
  }

  .pie-charts {
    height: 50%;
    padding-top: 6%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  canvas {
    height: 15rem !important;
    width: 15rem !important;
  }

  .total-orders-card {
    height: 32%;
    width: 30%;
    background-color: #fff;
    border-radius: 15px;
    padding: 1.5%;
    font-family: Arial, Helvetica, sans-serif;
    color: #53545c;
    flex-wrap: wrap;
    margin-bottom: 2%;
  }
  .active-orders {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.2vw;
    padding-top: 5%;
  }

  /* Orders css*/

  .order-body {
    height: 87%;
    width: 100%;
    background-color: #e8e6ff;
    padding: 2%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-y: scroll;
  }

  .order-summary-head {
    height: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5%;
    font-size: 1.5rem;
  }
  .create-new-order {
    font-size: 1.1vw;
    border-width: 0;
    background-color: #6759ff;
    color: #fff;
    width: 15.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 5px;
  }
  .assign-vendor {
    padding: 0.2rem 0.3rem;
    font-size: 1.1vw;
    border-width: 0;
    background-color: #6759ff;
    color: #fff;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2.5%;
    padding-right: 2.5%;
    border-radius: 5px;
  }
  .order-summary-view {
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1%;
  }
  .summary-view {
    height: 100%;
    width: 30%;
    background-color: #fff;
    border-radius: 15px;
    padding: 1%;
  }
  .summary-view1 {
    height: 100%;
    width: 60%;
    background-color: #fff;
    border-radius: 15px;
    padding: 1%;
    overflow-y: hidden;
  }

  .summary-view2 {
    height: 100%;
    width: 20%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-overflow: ellipsis;
  }
  .summary-view5 {
    height: 100%;
    width: 12% !important;
    margin-right: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis;
    border: 0;
    padding: 2% 0%;
    margin-top: -1%;
    border-radius: 0.3rem;
  }

  .summary-view6 {
    height: auto;
    width: 6% !important;
    margin-right: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-overflow: ellipsis;
    border: 0;
    padding: 0.5% 1%;
    margin-top: -1%;
    border-radius: 0.3rem;
  }
  .summary-view4 {
    font-family: "Instrument Sans", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    height: 20%;
    width: 100%;
    font-size: 0.7rem;
    color: #22222290;
    border: 2px solid transparent;
    margin-top: 2%;
  }

  .order-summary-body {
    height: auto;
    width: 100%;
    margin-top: 1%;
    background-color: #fff;
    padding: 1%;
    border-radius: 10px;
  }
  .order-body-header {
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #53545c;
    font-size: 1.2rem;
  }
  .order-body-header1 {
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #53545c;
    margin-top: 1%;
    margin-bottom: 1%;
    border-width: 1px;
    border-style: solid;
    border-left: none;
    border-right: none;
    border-color: #53545c50;
    font-size: 1vw;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
  }
  .order-body-para {
    height: auto;
    width: 18%;
    margin: 0;
    border-style: solid;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    border-color: transparent;
    padding-left: 2%;
    cursor: pointer;
    text-overflow: ellipsis;
  }

  .user-img {
    position: relative;
    height: 110%;
    width: 16%;
    cursor: pointer;
    border-radius: 0.2rem;
    margin-right: 20%;
  }

  .user-img:hover {
    position: absolute;
    height: 1000%;
    width: 100%;
    margin-top: -10rem;
    z-index: 5;
  }

  .order-body-para1 {
    height: 4vh;
    width: 10%;
    margin: 0;
    border-style: solid;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    border-color: transparent;
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.25%;
  }

  .order-para2 {
    position: relative;
    height: 4vh;
    width: 18%;
    margin: 0;
    overflow: visible;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2p red solid;
  }

  .order-body-select {
    height: 4vh;
    width: 11%;
    margin: 0;
    border-style: solid;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    border-color: #fff;
    margin-right: 5%;
    margin-left: -5%;
    background-color: #53545c20;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
  }

  .order-body-header2 {
    height: 3rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #53545c;
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 1vw;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
  }

  /*AddCustomer-modalbox*/
  .add-customer-modal-box {
    position: absolute;
    z-index: 3;
    background-color: #fff;
    height: 100vh;
    width: 25vw;
    top: 30%;
    left: 38%;
    border-radius: 15px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1vw;
    color: #53545c;
  }

  .add-customer-modal {
    position: absolute;
    z-index: 3;
    background-color: #fff;
    height: auto;
    width: 25vw;
    top: 30%;
    left: 38%;
    border-radius: 15px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1vw;
    color: #53545c;
  }

  .add-driver-modal {
    position: absolute;
    z-index: 3;
    background-color: #fff;
    height: auto;
    width: 25vw;
    top: 5%;
    left: 38%;
    border-radius: 15px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1vw;
    color: #53545c;
  }

  .coupon-modal-box {
    position: absolute;
    z-index: 3;
    background-color: #fff;
    height: auto;
    width: 25vw;
    top: 30%;
    left: 38%;
    border-radius: 15px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1vw;
    color: #53545c;
  }

  .add-customer-titles {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .add-customer-titles2 {
    margin-top: 1%;
    margin-bottom: 1%;
  }

  .add-customer-input-box {
    align-self: center;
    width: 90%;
    height: 5vh;
    margin-top: 2%;
    margin-bottom: 6%;
  }

  .add-driver-input-box {
    align-self: center;
    width: 90%;
    height: 8rem;
    margin-top: 2%;
    margin-bottom: 6%;
  }

  .add-customer-input-box2 {
    align-self: center;
    width: 90%;
    height: 5vh;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .PhoneInputInput {
    height: 110% !important;
  }
  .add-cutomer-button {
    height: 6vh;
    margin-top: 2%;
    width: 60%;
    align-self: center;
    border-width: 0px;
    border-radius: 10px;
    color: #fff;
    background-color: #6759ff;
  }

  /**AddVendor*/

  .add-vendor-modal-box {
    position: absolute;
    z-index: 3;
    background-color: #fff;
    height: 95vh;
    width: 70vw;
    top: 2.5%;
    left: 18%;
    border-radius: 15px;
    padding: 2%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 1vw;
    color: #53545c;
  }

  .assign-vendor-modal-box {
    position: absolute;
    z-index: 3;
    background-color: #fff;
    height: 95vh;
    width: 70vw;
    top: 2.5%;
    left: 18%;
    border-radius: 15px;
    padding: 2%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 1vw;
    color: #53545c;
  }

  .add-service-modal-box {
    position: absolute;
    z-index: 3;
    background-color: #fff;
    height: auto;
    width: 25vw;
    top: 2.1%;
    left: 38%;
    border-radius: 15px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 1vw;
    color: #53545c;
  }
  .vender-assigned-or-not {
    height: 1.5vh;
    width: 1%;
    background-color: red;
    border-radius: 100%;
    cursor: pointer;
  }
  .vender-assigned-or-not1 {
    height: 1.5vh;
    width: 1%;
    background-color: green;
    border-radius: 100%;
    cursor: pointer;
  }

  .vendor-assign-check {
    display: none;
    position: absolute;
    margin-top: 3.5%;
    margin-left: 1%;
    background-color: red;
    color: white;
    font-size: 0.8vw;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 5px;
  }
  .vendor-assign-check1 {
    display: none;
    position: absolute;
    margin-top: 3.5%;
    margin-left: 1%;
    background-color: green;
    color: white;
    font-size: 0.8vw;
    padding-left: 1%;
    padding-right: 1%;
    border-radius: 5px;
  }
  .vender-assigned-or-not:hover + .vendor-assign-check {
    display: inline;
  }
  .vender-assigned-or-not1:hover + .vendor-assign-check1 {
    display: inline;
  }
}
