@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;900&display=swap");

*::selection {
  background-color: transparent;
}

.spinner-con {
  position: absolute;
  width: 43vw;
  height: 65vh;
  margin-left: 50%;
  margin-top: 13%;
  background: #ffffff;
  border-radius: 15px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.add-Clothes-containerB {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.add-Clothes-container-D {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 9.23%;
}

.set-counter-b {
  height: 40%;
  width: 12vw;
  background-color: transparent;
  border-color: #6759ff;
  border-style: solid;
  border-width: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  margin-right: 1vw;
  margin-left: 1vw;
  padding-top: 2%;
  padding-bottom: 1%;
  position: relative;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.set-counter-button {
  width: 2vw;
  background-color: transparent;
  border-width: 0px;
  color: #6759ff;
  font-size: 1.5vw;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  margin-bottom: 1vh;
}

.set-counter-para {
  font-size: 3vh;
  color: #6759ff;
  margin-top: -4%;
  width: 15%;
  text-align: center;
}

.set-counter-icon {
  color: #6759ff;
  height: 55%;
  width: 23%;
  margin-top: -5%;
}

.set-counter-price {
  display: none;
}

.set-counter-price-icon {
  font-size: 2.2vh;
  color: #6759ff;
  width: 15%;
  left: 69%;
  text-align: center;
  position: absolute;
  top: 3.5%;
}

.set-couter-price-icon3 {
  font-size: 2.1vh;
  color: #6759ff;
  width: 15%;
  left: 65%;
  text-align: center;
  position: absolute;
  top: 3.5%;
}

.set-couter-price-price3 {
  font-size: 2.2vh;
  color: #6759ff;
  width: 15%;
  left: 76%;
  text-align: center;
  position: absolute;
  top: 1.25%;
}

.total-con-b {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 5%;
  right: 5%;
  color: #6759ff;
}

.set-counter-name {
  font-family: "Poppins", sans-serif;
  font-size: 1.75vh;
  color: #6759ff;
  position: absolute;
  font-weight: bold;
  left: 3%;
  bottom: 2.5%;
}

.set-counter-amount {
  font-family: "Poppins", sans-serif;
  font-size: 1.75vh;
  color: #6759ff;
  position: absolute;
  font-weight: bold;
  left: 3%;
  top: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter-total-price {
  font-size: 2.5vh;
  color: #6759ff;
  width: 15%;
  left: 82%;
  text-align: center;
  position: absolute;
  font-weight: bold;
  top: 6.5%;
}

.counter-total-value {
  font-size: 2.5vh;
  color: #6759ff;
  width: 15%;
  left: 73.5%;
  text-align: center;
  position: absolute;
  font-weight: bold;
  top: 6.2%;
}

.wash-button {
  width: 80%;
  background-color: #6759ff;
  border-radius: 10px;
  color: #fff;
  padding: 3%;
  border-width: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 1.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5%;
}

.wash-clothes-icon {
  margin-left: 1%;
  font-weight: bold;
}
.wash-button:hover {
  background-color: #5647fe;
}

.add-clothes-error-msg {
  position: absolute;
  font-family: "Poppins", sans-serif;
  color: red;
  font-size: 1.1vw;
  bottom: 4%;
}

.add-clothes-error-msg-disable {
  display: none;
}

.counter-buttons-container-b {
  height: 80%;
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.items-over-b {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: left;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 33.5vh;
  padding: 0% 4%;
}

.clothes-add-b {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 85%;
  padding-top: 0.5%;
  margin-right: -1%;
}

.pick-button-b {
  margin-left: 10%;
  width: 70%;
  border-style: solid;
  border-color: #5647fe;
  border-width: 2px;
  border-radius: 5px;
  color: #5647fe;
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  font-weight: bold;
  background-color: transparent;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  cursor: pointer;
}

.pick-button-b:hover {
  background-color: #6759ff;
  color: #fff;
}

.button-pick-b {
  margin-left: 10%;
  width: 70%;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 1vw;
  font-weight: bold;
  background-color: #6759ff;
  border-color: #6759ff;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.type-of-wash-continue-addClothes2 {
  width: 35%;
  cursor: pointer;
  cursor: pointer;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .add-Clothes-containerB {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .add-Clothes-containerB::-webkit-scrollbar {
    display: none;
  }
  .counter-buttons-container-b {
    width: 100% !important;
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    margin-top: 0%;
    margin-bottom: 0%;
    padding-top: 0.5%;
    position: sticky;
    top: 0;
  }
  .button-pick-b {
    width: auto !important;
    font-size: 0.65rem;
    margin: 0;
  }
  .pick-button-b {
    font-size: 0.65rem;
    width: auto !important;
    margin: 0%;
  }
  .reorder-animate-B {
    top: 1.6%;
  }
  .items-over-b {
    height: 35vh !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
  }
  .set-counter-name {
    font-size: 0.7rem !important;
  }

  .type-of-wash-continue-addClothes1 {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    font-size: 0.7rem;
    padding: 1% 5% !important;
    right: 4%;
  }

  .type-of-wash-continue-addClothes2 {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    width: 70% !important;
    padding: 1% 2.5% !important;
    cursor: pointer;
    font-size: 0.7rem !important;
  }

  .type-of-wash-continue-addClothes2 span {
    font-size: 0.6rem !important;
  }

  .set-counter-amount > svg {
    margin-bottom: 15% !important;
  }
}

@media screen and (max-width: 767px) {
  .add-Clothes-container-D {
    margin-top: 5%;
    height: 40vh;
  }

  .add-Clothes-containerB {
    margin-top: 5%;
    height: 40vh;
    overflow-y: scroll;
  }

  .type-of-wash-continue-addClothes1 {
    position: relative;
    background-color: #6759ff;
    border-radius: 0.25rem;
    color: #fff;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-right: 1.5%;
    padding-left: 1.5%;
    border-width: 0px;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    font-weight: bold;
    margin-right: 5%;
    margin-top: 6%;
  }

  .type-of-wash-continue-addClothes2 {
    position: relative;
    background-color: #6759ff;
    border-radius: 0.25rem;
    color: #fff;
    padding: 1.25% 1.25%;
    border-width: 0px;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    font-weight: bold;
    margin-right: 5%;
    width: 28%;
    margin-top: 5.73%;
  }
  .set-counter-b > button:hover {
    color: #6759ff;
    background-color: #ffffff;
    border: 0;
    margin-bottom: 8%;
  }

  .set-counter-b > button {
    border: 0;
    margin-bottom: 8%;
  }

  .set-counter-b > img {
    height: 55%;
  }

  .set-counter-b {
    width: 47%;
    background-color: transparent;
    border-color: #6759ff;
    border-style: solid;
    border-width: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    margin-right: 1vw;
    margin-left: 1vw;
    position: relative;
    margin-top: 5%;
    margin-bottom: 2%;
    padding-top: 5%;
    padding-bottom: 4%;
    height: auto;
  }

  .items-over-b {
    width: 100%;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    margin-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .clothes-add-b {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .pick-button-b {
    color: #6759ff;
    border: 2px solid #6759ff;
    background-color: #ffffff;
    border-radius: 0.25rem;
  }

  .pick-button-b:hover {
    background-color: #6759ff;
    color: #fff;
  }

  .button-pick-b {
    color: #ffffff;
    border: 2px solid #6759ff;
    background-color: #6759ff;
    border-radius: 0.25rem;
  }
  .counter-buttons-container-b {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 5%;
  }

  .set-couter-price-icon3 {
    display: none;
  }

  .set-couter-price-price3 {
    display: none;
  }

  .spinner-con {
    position: relative;
    width: 85%;
    height: 57.5vh;
    margin-top: 5%;
    margin-left: 8%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .add-Clothes {
    position: relative;
    width: 85%;
    height: auto;
    margin-top: 5%;
    margin-left: 8%;
    background: #ffffff;
    border-radius: 15px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    display: flex;
    overflow: hidden;
    padding-top: 10%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    flex-wrap: wrap;
  }
  .set-counter {
    width: 47%;
    background-color: transparent;
    border-color: #6759ff;
    border-style: solid;
    border-width: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    margin-right: 1vw;
    margin-left: 1vw;
    position: relative;
    margin-top: 5%;
    margin-bottom: 2%;
    padding-top: 2%;
    height: 21%;
  }

  .set-counter-button {
    background-color: transparent;
    border-width: 0px;
    color: #6759ff;
    font-size: 2.5vh;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1vh;
    border: 1px solid transparent;
    border-radius: 50%;
    margin-top: 3%;
    height: 25%;
    width: 5%;
  }

  .set-counter-para {
    font-size: 2.5vh;
    color: #6759ff;
    width: 15%;
    text-align: center;
    font-weight: bold;
    margin-top: -5%;
  }

  .set-counter-icon {
    color: #6759ff;
    height: 45%;
    width: 18.5%;
    position: relative;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: -7%;
  }

  .set-counter-price {
    font-family: "Poppins", sans-serif;
    font-size: 1.2vh;
    color: #6759ff;
    position: absolute;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    right: 4%;
    top: 0%;
  }

  .set-counter-price > svg {
    margin-bottom: 15% !important;
  }
  .set-counter-total1 {
    font-size: 1.7vh;
    color: #6759ff;
    width: 15%;
    left: 75.5%;
    text-align: center;
    position: absolute;
    top: 3.5%;
  }
  .set-counter-price-icon {
    font-size: 1.4vh;
    color: #6759ff;
    width: 15%;
    left: 64%;
    text-align: center;
    position: absolute;
    top: 5.3%;
  }

  .set-counter-name {
    font-family: "Poppins", sans-serif;
    font-size: 1.45vh;
    color: #6759ff;
    position: absolute;
    left: 4%;
    bottom: 2.5%;
  }

  .set-counter-amount {
    font-family: "Poppins", sans-serif;
    font-size: 1.2vh;
    color: #6759ff;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: 900;
    left: 4%;
    top: 0%;
  }

  .counter-total-price {
    font-size: 2vh;
    color: #6759ff;
    width: 15%;
    left: 82%;
    text-align: center;
    position: absolute;
    font-weight: bold;
    top: 2.7%;
  }

  .counter-total-value {
    font-size: 2vh;
    color: #6759ff;
    width: 15%;
    left: 66%;
    text-align: center;
    position: absolute;
    font-weight: bold;
    top: 2.5%;
  }

  .wash-button {
    width: 90%;
    background-color: #6759ff;
    border-radius: 10px;
    color: #fff;
    padding-top: 5%;
    padding-bottom: 5%;
    border-width: 0px;
    font-family: "Poppins", sans-serif;
    font-size: 4vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .wash-clothes-icon {
    margin-left: 1%;
    font-weight: bold;
  }
  .wash-button:hover {
    background-color: #5647fe;
  }

  .add-clothes-error-msg {
    position: absolute;
    font-family: "Poppins", sans-serif;
    color: red;
    font-size: 2.8vw;
    height: 0.5%;
    bottom: 4%;
  }

  .add-clothes-error-msg-disable {
    display: none;
  }

  .counter-buttons-container {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    display: inline;
    position: absolute;
    top: 7.5%;
  }

  .clothes-add {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 5%;
  }
  .items-over {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: 40vh;
    margin-top: 5%;
    overflow-y: scroll;
    overflow-x: hidden;
    align-content: flex-start;
  }

  .pick-button {
    margin-top: 1.5%;
    margin-left: 5%;
    margin-right: 2%;
    border-style: solid;
    border-color: #5647fe;
    border-width: 1px;
    border-radius: 2px;
    color: #5647fe;
    font-family: "Poppins", sans-serif;
    font-size: 2.3vw;
    font-weight: bold;
    background-color: transparent;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .pick-button:hover {
    background-color: #6759ff;
    color: #fff;
  }

  .button-pick {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    margin-top: 1.5%;
    margin-left: 5%;
    margin-right: 2%;
    border-style: solid;
    border-color: #5647fe;
    border-width: 1px;
    border-radius: 2px;
    color: #fff;
    background-color: #6759ff;
    font-family: "Poppins", sans-serif;
    font-size: 2.3vw;
    font-weight: bold;
  }
}

@media screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
  .set-counter-b {
    height: 30%;
  }

  .set-counter-b p {
    font-size: 0.6rem;
  }
  .set-couter-price-icon3 {
    font-size: 0.6rem;
  }
  .set-counter-para {
    margin-top: -6%;
    font-size: 1rem !important;
  }
  .type-of-wash-continue-addClothes1 {
    bottom: 8%;
  }
  .type-of-wash-continue-addClothes2 {
    width: 40%;
    bottom: 8%;
    cursor: pointer;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: portrait) {
  .set-counter-name {
    font-size: 0.7rem !important;
  }
  .set-couter-price-icon3 {
    font-size: 1rem;
    margin-top: 3%;
  }

  .set-couter-price-price3 {
    font-size: 0.8rem !important;
    margin-top: 0.5%;
  }
  .set-counter-amount {
    font-size: 0.8rem !important;
    margin-top: 0.1%;
  }

  .type-of-wash-continue-addClothes1 {
    font-size: 0.6rem !important;
  }
  .type-of-wash-continue-addClothes2 {
    font-size: 0.6rem !important;
    height: 8%;
    cursor: pointer;
  }
}

@media screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
  .set-counter-name {
    font-size: 0.5rem !important;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1024px) and (orientation: landscape) {
  .set-counter-name {
    font-size: 0.7rem !important;
  }
}

@media screen and (max-width: 350px) and (orientation: portrait) {
  .type-of-wash-continue-addClothes2 {
    width: 90% !important;
  }
  .type-of-button-addClothes1 {
    margin: 0.25rem 3% !important;
  }
  .type-of-button-addClothes {
    margin: 0.25rem 3% !important;
  }
}

@media screen and (max-width: 376px) and (orientation: portrait) {
  .search-box2 {
    width: 60% !important;
  }
}
