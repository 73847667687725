/**New Css**/

@keyframes hideDataAnimation {
  0% {
    transform: translate(0%);
    opacity: 100%;
  }
  25% {
    transform: translate(-120%);
    opacity: 0%;
  }
  50% {
    transform: translate(120%);
    opacity: 0%;
  }
  100% {
    transform: translate(0%);
    opacity: 100%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@500&display=swap");

:root {
  --def-color: #6759ff;
  --def-font: "Poppins", sans-serif;
}

.slider-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
}

.slider-container2 {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
}

.slider-container2 li {
  border: 2px solid #222222;
  width: 80% !important;
  margin-top: 5%;
  padding: 2%;
  border-radius: 1rem;
  animation: hideDataAnimation 1.25s normal;
}

.slider-container2 > li > div {
  width: 20%;
  margin-left: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider-container2 > li > div img {
  width: 10%;
}

.slider-container2 h3 {
  font-family: var(--def-font);
  text-align: center;
  font-size: 1.5rem;
  margin: 1% 0%;
}

.slider-container2 h4 {
  font-family: var(--def-font);
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  margin: 1% 0%;
}

.slider-container2 p {
  font-family: "Caveat";
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  color: grey;
  max-width: 90%;
  margin: 1% 0%;
  margin-left: 5%;
}

#customer-image1 {
  width: 80%;
  transition: all 0.5s linear;
  transform: scale(1);
  border: 2px solid #6759ff;
  background-color: #6759ff;
  border-radius: 50%;
}

#customer-image2 {
  width: 80%;
  transition: all 0.5s linear;
  transform: scale(1.5);
  border: 2px solid #6759ff;
  background-color: transparent;
  border-radius: 50%;
  padding: 5%;
}

.sliding {
  margin-top: 5%;
  margin-bottom: -10%;
}

.sliding h2 {
  font-family: "Caveat";
  color: var(--def-color);
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 3rem;
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  #customer-image1 {
    width: 50%;
    transition: all 0.5s linear;
    transform: scale(1);
    border: 2px solid #6759ff;
    background-color: #6759ff;
    border-radius: 50%;
  }

  #customer-image2 {
    width: 50%;
    transition: all 0.5s linear;
    transform: scale(1.5);
    border: 2px solid #6759ff;
    background-color: transparent;
    border-radius: 50%;
    padding: 5%;
  }

  .slider-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .slider-container2 {
    width: 100%;
    margin-left: -5%;
    margin-top: 10%;
  }

  .slider-container2 > li > div {
    width: 80%;
    margin-left: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .slider-container2 p {
    font-family: "Caveat";
    font-size: 0.8rem;
  }
}

/**New Css**/

/* TestimonialSlider.css */

.testimonial {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
  padding: 20px;
  position: relative; /* Needed for customer images */
}

.testimonial p {
  font-size: 0.7rem;
}

.testimonial-name {
  margin-top: 10px;
  font-weight: bold;
}

.customer-image {
  height: 200px !important;
  width: 200px !important;
  /* Adjust the size as needed */
  border-radius: 5px; /* Rounded customer images */
  object-fit: cover; /* Ensure images maintain aspect ratio */
}

.slick-dots {
  margin: -1%;
}

/* Apply styles to individual dots */

/* Media query for screens with a minimum width of 992px */

.testimonial-slider {
  height: auto;
  width: 100%;
  border-style: solid;
  margin-bottom: -13%;
  padding-left: 2%;
  padding-right: 2%;
  background-color: transparent;
  border-color: transparent;
  margin-top: 10%;
}

.testimonial-head {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 3%;
  margin-bottom: 7%;
  font-size: 2.5vw;
  color: #6759ff;
}
.testimonial {
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
}
.testimonial p {
  margin-top: 5%;
  font-size: 1vw;
}

/* Custom arrows for the slider */
.custom-arrow {
  width: 30px;
  height: 30px;
  background-color: #33333370;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 50%;
  z-index: 1;
  transition: background-color 0.3s ease;
  padding-bottom: 0.23%;
}

.custom-arrow:hover {
  background-color: #555;
}

.prev {
  left: 5px;
}

.next {
  right: 5px;
}

/* Media query for screens with a maximum width of 660px */
@media screen and (max-width: 660px) {
  .testimonial-slider {
    height: auto;
    width: 100%;
    border-style: solid;
    margin-bottom: -18%;
    padding-left: 2%;
    padding-right: 2%;
    background-color: transparent;
    border-color: transparent;
  }

  .testimonial-head {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10%;
    font-size: 4vw;
    color: #6759ff;
  }
  .testimonial {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 7%;
    padding-right: 13%;
  }
  .testimonial p {
    margin-top: 10%;
    font-size: 3vw;
    width: 95%;
    padding-left: 5%;
  }
  .customer-image {
    width: 85px; /* Adjust the size as needed */
    height: 85px; /* Adjust the size as needed */
    border-radius: 5px; /* Rounded customer images */
    object-fit: cover; /* Ensure images maintain aspect ratio */
    align-self: center;
    margin-left: 18%;
  }
}

@media screen and (min-width: 661px) and (max-width: 1024px) and (orientation: portrait) {
  .testimonial p {
    font-size: 0.95rem;
  }
}
