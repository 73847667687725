@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;900&display=swap");

:root {
  --def-color: #6759ff;
  --def-font: "Poppins", sans-serif;
}

.footer-container {
  margin-top: 15%;
  position: relative;
  background-color: #e1e1e1;
}

.footer-first-con {
  height: auto;
  width: 80%;
  position: absolute;
  background-color: #1e3661;
  border-radius: 2rem;
  overflow: hidden;
  padding: 3% 5%;
  margin-left: 10%;
  top: -18%;
}

.footer-first-con > p {
  font-family: var(--def-font);
  font-size: 2.2rem;
  font-weight: 900;
  max-width: 70%;
  color: #ffffff;
}

.footer-first-con img {
  position: absolute;
  top: 0;
  right: -8%;
}

.footer-first-con button {
  font-family: var(--def-font);
  font-size: 1.2rem;
  position: absolute;
  top: 40%;
  right: 17%;
  z-index: 2;
  padding: 1% 3%;
  border: 0;
  color: var(--def-color);
  font-weight: 700;
  border-radius: 0.5rem;
  cursor: pointer;
}

.footer-second-con {
  margin-top: 8%;
}

.footer-second-con > div {
  height: auto;
  width: 100%;
}

.contact-section-con {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
}

.contact-section-con div:first-child {
  width: 30%;
}

.contact-section-con div:first-child img {
  width: 80%;
  cursor: pointer;
}

.contact-section-con div:first-child p {
  font-family: var(--def-font);
  font-size: 0.75rem;
  color: #808080;
  margin-left: 1%;
  font-weight: 500;
  width: 100%;
}

#subscribe-para {
  font-family: var(--def-font);
  font-size: 1rem;
  color: #222222;
  margin-left: 1%;
  font-weight: 700;
  margin-top: 2%;
  margin-bottom: 1%;
}

.subscribe-email {
  width: 100%;
  position: relative;
}

.subscribe-email input {
  margin-left: 2%;
  border: 0;
  padding: 3%;
  width: 100%;
  border-radius: 0.25rem;
}

.subscribe-email button {
  position: absolute;
  height: 80%;
  width: 30%;
  border: 0;
  right: 0;
  background-color: var(--def-color);
  font-family: var(--def-font);
  color: #ffffff;
  top: 10%;
  border-radius: 0.3rem;
  cursor: pointer;
}

.footer-subsection {
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
}

#items-subs1,
#items-subs2,
#items-subs3 {
  height: auto;
  width: 20%;
  cursor: pointer;
}

#items-subs1 h1 {
  font-family: var(--def-font);
  font-size: 1rem;
  font-weight: 700;
  margin: 1% 0%;
}

#items-subs2 h1 {
  font-family: var(--def-font);
  font-size: 1rem;
  font-weight: 700;
  margin: 1% 0%;
}

#items-subs3 h1 {
  font-family: var(--def-font);
  font-size: 1rem;
  font-weight: 700;
  margin: 1% 0%;
}

#items-subs1 > p {
  font-family: var(--def-font);
  font-size: 0.7rem;
  font-weight: 700;
  margin: 1% 0%;
  color: #808080;
}

#items-subs2 > p {
  font-family: var(--def-font);
  font-size: 0.7rem;
  font-weight: 700;
  margin: 1% 0%;
  color: #808080;
}

#items-subs3 > p {
  font-family: var(--def-font);
  font-size: 0.7rem;
  font-weight: 700;
  margin: 1% 0%;
  color: #808080;
}

.copy-rights {
  font-family: var(--def-font);
  font-size: 0.8rem;
  text-align: center;
  cursor: pointer;
}

.subscribe-icons-con {
  width: 40%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.subscribe-icons-con > svg {
  margin-top: 5%;
  font-size: 2rem;
  color: #dd246e;
  cursor: pointer;
}

@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: portrait) {
  .footer-first-con p {
    font-size: 1.2rem;
    width: 60%;
  }

  .footer-first-con {
    border-radius: 0.5rem;
  }

  .footer-first-con img {
    right: -30%;
  }

  .subscribe-email {
    width: 100%;
  }

  .subscribe-email button {
    width: 40%;
    font-size: 0.8rem;
  }
  #subscribe-para {
    font-size: 0.7rem;
  }

  .contact-section-con div p {
    font-size: 0.6rem !important;
  }

  .footer-first-con {
    width: 90%;
    margin-left: 5%;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) and (orientation: landscape) {
  .footer-first-con p {
    font-size: 1.5rem;
    width: 60%;
  }
  .footer-first-con {
    width: 90%;
    margin-left: 5%;
    border-radius: 0.5rem;
  }
  .subscribe-email button {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  .footer-first-con > img {
    right: -15%;
  }
}

@media screen and (max-width: 600px) and (orientation: portrait) {
  .contact-section-con {
    height: auto;
    margin-top: 30%;
  }

  .footer-first-con {
    width: 90%;
    margin-left: 5%;
    border-radius: 0.5rem;
    top: -6.5%;
  }

  .footer-first-con p {
    font-size: 0.8rem;
    max-width: 80%;
  }
  .footer-first-con img {
    right: -90%;
    transform: rotate(0deg);
    top: -100%;
  }
  .footer-first-con button {
    font-size: 0.5rem;
    border-radius: 0.25rem;
    right: 10%;
  }
  .contact-section-con {
    flex-direction: column;
    align-items: flex-start;
  }
  .contact-section-con div {
    width: 100% !important;
  }
  .subscribe-icons-con {
    justify-content: flex-start;
  }
  .subscribe-icons-con svg {
    margin-left: 5%;
  }
  .footer-subsection {
    height: auto;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 5%;
  }
  #items-subs1,
  #items-subs2,
  #items-subs3 {
    width: 50% !important;
    margin-top: 5%;
    padding-left: 5%;
  }
}
